<template>
    <div>
        <v-container fluid>
            <v-row justify="center">
                <v-col
                    cols="12"
                    lg="12"
                    xl="9"
                >
                    <v-card flat color="" class="mr-2 d-flex overflow-x-hidden" key="publisher-dialog">
                        <v-card v-if="!from_calendar" flat class="d-flex align-center" color="lime" @click="eventClosePublisherDialog" key="return-arrow">
                            <v-card flat color="transparent">
                                <v-icon large color="Dialog">mdi-arrow-left-thick</v-icon>
                            </v-card>
                        </v-card>
                        <v-card flat key="publisher-dialog-card" color="" width="97%">
                            <Publisher
                                @eventClosePublisherDialog="eventClosePublisherDialog"
                                :deck_cluster="cluster"
                                :cl_index="cl_index"
                                :selected_images_urls="selected_images_urls"
                                :selected_posts_index="selected_posts_index"
                                :posts="buildSelectedPosts"
                            />
                        </v-card>
            
                    </v-card>
                </v-col>
            </v-row>
            

        </v-container>
    </div>
</template>

<script>

import Publisher from './Publisher/PublisherMain.vue'

export default {

    name: 'PublisherDialog',

    components: {
        Publisher,
    },

    props: ['cluster','cl_index','from_calendar'],

    data: () => ({

        // images
        selected_images_urls: [],

        // posts
        selected_posts_index:[0],

    }),

    methods: {
        
        eventClosePublisherDialog() {
            // route from calendar
            if (this.from_calendar) {
                this.$router.go(-1);
            } else {
                this.$emit("eventClosePublisherDialog");
                console.log("eventClosePublisherDialog dialog")
            }
        },

        eventSelectedImages(selected_images) {
            this.selected_images_urls = selected_images;
        },

        eventSelectedPosts(selected_posts_index) {
            this.selected_posts_index = selected_posts_index;
        },
    },

    computed: {

        buildSelectedPosts() {
            let selected_posts = [];

            if (this.cluster && this.selected_posts_index.length > 0) {
                for (let index of this.selected_posts_index) {
                    selected_posts.push(this.cluster.posts[index]);
                }
            }
            return selected_posts
        },
    }
}
</script>

<style scoped>

</style>