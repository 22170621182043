<template>
    <div>
        <v-card flat color="Background" key="subscriber-tab-news">

            <!-- loading -->
            <v-progress-linear 
                indeterminate 
                :color="newsColor"
                :active="loading"
            ></v-progress-linear>

            <Header 
                :languageMain="language" 
                @eventLanguage="eventLanguage" 
                :countryMain="country"
                @eventCountry="eventCountry" 
                @eventReload="eventReload" 
                :color="newsColor"
                stream="news"
                class="mt-2"
            ></Header>

            <!-- TRANSLATIONS -->
            <!-- <TranslationsSelectWidget /> -->

            <!-- error msg -->
            <span v-if="error && error != 'empty'" class="ml-5 mt-2 error--text">API Error: {{ error }}</span>

            <!-- no post msg -->
            <v-card v-if="error == 'empty'" flat color="transparent">
                <v-card-text class="text-center">{{ $i18n.t("noMorePost") }}</v-card-text>
            </v-card>

            <!-- Pagination -->
            <v-card v-if="total > options.itemsPerPage" class="mx-3" outlined flat>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <span>{{ total }} posts</span>
                    <v-spacer></v-spacer>
                    <v-pagination v-model="page" @input="inputPage" :length="paginationLength" total-visible="10"
                        color="accent darken-3" circle>
                    </v-pagination>
                    <v-spacer></v-spacer>
                    <v-card flat max-width="150" class="py-0">
                        <v-card-text>
                            <v-text-field
                                @click:append-outer="goPage"
                                dense
                                v-model.number="number_page"
                                type="number"
                                outlined
                                label="Page"
                                hide-details=""
                                append-outer-icon="mdi-eye"
                            ></v-text-field>
                        </v-card-text>
                    </v-card>
                </v-card-actions>
            </v-card>
           

            <v-sheet outlined rounded v-for="(post, index) in posts" :key="'post_' + post.id" class="my-2 mx-3"
                :color="sheetPostColor(index)">
                <v-card flat class="d-flex">
                    
                    <!-- Widgets -->
                    <v-card class="mt-2" color="transparent">
                        <PublisherWidget
                            v-if="post.source_ref != 'FR046'"
                            :post="post"
                            @eventGoPublisher="eventGoPublisher"
                        ></PublisherWidget>
                        <PoolWidget 
                            :post="post" 
                            :index="index" 
                            position="vertical"
                            @eventPostAddedInPool="eventPostAddedInPool" 
                            @eventPostRemoveFromPool="eventPostRemoveFromPool"
                        ></PoolWidget>
                        <VoteWidget v-if="isUserProfileTrainer" 
                            @eventVote="eventVote" 
                            :post="post" 
                            :index="index"
                            tab="subscriber"
                            stream="post"  
                            class="mt-2"
                        ></VoteWidget>
                    </v-card>
                   
                    <!-- Post -->
                    <Post 
                        :post="post" 
                        :index="index" 
                        :colorView="sheetPostColor(index)"
                        tab="news" 
                        @eventGoSingle="eventOpenSingleDialog"
                        @eventGoTags="eventGoTags"
                        @eventGoLoot="eventGoLoot"
                        @eventGoCorpus="eventGoCorpus"
                        @eventGoMiner="eventGoMiner"
                        :key='"p" + post.id'
                    ></Post>
                </v-card>
            </v-sheet>

            <!-- Pagination -->
            <div v-if="total > options.itemsPerPage" class="text-center my-3">
                <v-pagination v-model="page" @input="inputPage" :length="paginationLength" total-visible="10"
                    color="accent darken-3" circle>
                </v-pagination>
            </div>

            <!-- DIALOG SINGLE -->
            <v-dialog v-model="dialogSingle" key="news-dialog-single" fullscreen>
                <!-- v-card needed for triggering fullscreen-->
                <v-card>
                    <Single v-if="dialogSingle" 
                        :single="dialogSinglePost" 
                        :index="dialogSingleIndex"
                        :isUserProfileTrainer="isUserProfileTrainer" 
                        :colorView="newsColor"
                        tab="subscriber"
                        @eventPostAddedInPool="eventPostAddedInPool" 
                        @eventPostRemoveFromPool="eventPostRemoveFromPool"
                        @eventCloseSingleDialog="eventCloseSingleDialog" 
                        @eventVote="eventVote"
                        @eventTranslation="eventTranslation"
                        @eventGoTags="eventGoTags"
                        @eventGoLoot="eventGoLoot"
                        @eventGoCorpus="eventGoCorpus"
                        @eventGoPublisher="eventGoPublisher"
                        @eventGoMiner="eventGoMiner"
                        @eventGoSource="eventGoSource"
                    ></Single>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import subscriber from "@/common/subscriber";
import Post from '../components/Post'
import Header from '../components/HeaderLangCountry'
import PoolWidget from './SubscriberPoolWidget'
import VoteWidget from '../../Vote/VoteWidget.vue'
import PublisherWidget from '../../Deck/PublisherPostWidget'
import Single from './SubscriberDialogSingle'
//import TranslationsSelectWidget from '../../Translations/TranslationsSelectWidget.vue'

export default {

    name: 'News',

    components: {
        Header,
        Post,
        PoolWidget,
        VoteWidget,
        PublisherWidget,
        Single,
        //TranslationsSelectWidget,
    },

    props: ['propMainPostRemoveFromPool'],

    data: () => ({
        loading: false,
        error: null,
        posts: [],
        language: null,
        country: null,

        newsColor: "accent",

        // dialog single
        dialogSingle: false,
        dialogSinglePost: null,
        dialogSingleIndex: null,

        scrolling: 0,

        //trainer widget
        isUserProfileTrainer: false,

        //* PAGING
        total: null,
        page: 1,
        number_page: 1,
        paginationLength: null,
        options: {
            itemsPerPage: 25,
        },
    }),

    created() {
        // init
        this.language = subscriber.retriveNewsLanguage();
        this.country = subscriber.retriveNewsCountry();
        this.page = subscriber.retriveNewsPage();
        this.number_page = subscriber.retriveNewsPage();
        this.dialogSingle = subscriber.retriveNewsDialog();
        this.dialogSingleIndex = subscriber.retriveNewsIndex();
        this.dialogSinglePost = subscriber.retriveNewsSingle()
        this.scrolling = subscriber.retriveNewsScroll();
        // trainer widget : fetch full posts meta
        if (this.$rbac.get_user_role() == "admin" || this.$rbac.get_user_role().includes("trainer")) {
            this.isUserProfileTrainer = true;
            this.fetchTrainerNewsPosts()
            .then(() => { this.dialogSinglePost = this.posts[this.dialogSingleIndex]})
            .then( () => {
                if (!this.tabDialogSingle) {
                    window.scrollTo(0,this.scrolling);
                }
            });
        } else {
            this.fetchSubscriberPosts() .then( () => {
                window.scrollTo(0,this.scrolling);
            })
            .then(() => { this.dialogSinglePost = this.posts[this.dialogSingleIndex]})
            .then( () => {
                if (!this.tabDialogSingle) {
                    window.scrollTo(0,this.scrolling);
                }
            });
        }
    },

    watch: {
        propMainPostRemoveFromPool: {
            deep: true,
            immediate: true,
            handler() {
                for (let p of this.posts) {
                    if (p.id == this.propMainPostRemoveFromPool) {
                        p.is_pooled = false;
                    }
                }
            }
        }
    },

    methods: {

        fetchSubscriberPosts: async function () {
            console.log("fetch posts")
            this.loading = true;
            this.error = null;
            this.posts =[];
            this.total = 0;
            let filter = (
                "?size=" + this.options.itemsPerPage +
                "&page=" + (this.page - 1) +
                this.languageApiFilter() +
                this.countryApiFilter()
            );

            try {
                let res = await this.$api.get("/profile/subscriber/list" + filter);
                this.posts = res.data
                this.total = parseInt(res.headers["x-paginated-items"]);
                this.paginationLength = Math.ceil(this.total / this.options.itemsPerPage);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;

            } finally {
                this.loading = false;
            }
        },

        fetchTrainerNewsPosts: async function () {
            console.log("fetch trainer news posts")
            this.loading = true
            this.error = null;
            this.posts =[];
            this.total = 0;
            let filter = (
                "?size=" + this.options.itemsPerPage +
                "&page=" + (this.page - 1) +
                this.languageApiFilter() +
                this.countryApiFilter() +
                "&user_lang=" + this.$auth.retriveUserField("language_ui")
            );

            try {
                let res = await this.$api.get("/profile/trainer/news" + filter);
                this.posts = res.data
                this.total = parseInt(res.headers["x-paginated-items"]);
                this.paginationLength = Math.ceil(this.total / this.options.itemsPerPage);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        //* NAVIGATION
        inputPage(pg) {
            this.page = pg;
            subscriber.setNewsPage(pg);
            if (this.isUserProfileTrainer) {
                this.fetchTrainerNewsPosts();
            } else {
                this.fetchSubscriberPosts();
            }
        },

        goPage() {
            console.log("go page", this.number_page)
            this.posts = [];
            this.inputPage(this.number_page)
        },


        //* HEADER

        // Header Events
        eventLanguage(lg) {
            subscriber.setNewsLanguage(lg);
            this.page = 1
            this.language = lg;
            this.posts = [];
            if (this.isUserProfileTrainer) {
                this.fetchTrainerNewsPosts();
            } else {
                this.fetchSubscriberPosts();
            }
        },

        eventCountry(country) {
            subscriber.setNewsCountry(country);
            this.page = 1
            this.country = country;
            this.posts = [];
            if (this.isUserProfileTrainer) {
                this.fetchTrainerNewsPosts();
            } else {
                this.fetchSubscriberPosts();
            }
        },

        eventReload() {
            this.posts = [];
            if (this.isUserProfileTrainer) {
                this.fetchTrainerNewsPosts();
            } else {
                this.fetchSubscriberPosts();
            }
        },

        // Header filters
        countryApiFilter() {
            if (this.country != "all") {
                return "&country=" + this.country
            }
            return ""
        },

        languageApiFilter() {
            if (this.language != "all") {
                return "&language=" + this.language
            }
            return ""
        },

       
        //* Translations
        eventTranslation(index, output_language,content,description,title) {
            console.log("eventTranslation", index, output_language, content.length, description.length,title)
            if (this.posts[index].translations.length > 0) {
                for (let tr of this.posts[index].translations) {
                    if (tr.output_language == output_language) {
                        if (title.length > 0) {
                            tr.title =title
                        }
                        if (description.length > 0) {
                            tr.description = description;
                        }
                        tr.content = content;
                    }
                }
            }
        },

        //* DECK/PUBLISHER
        eventGoPublisher(id) {
            console.log("eventGoPublisher",id)
            subscriber.setNewsScroll(window.scrollY);
            this.$router.push({
                name: "deck",
                params: {
                    post_id: id,
                    post_ids: null,
                }
            })
        },

        // Go Miner
        eventGoMiner(index) {
            subscriber.setNewsScroll(window.scrollY);
            this.$router.push({path: "/main/miner_prompt/" + this.posts[index].id})
        },

        //* POOL
        removeFromPool: async function (id) {
            this.loading = true;
            await this.$api.get("/profile/subscriber/pool/remove/" + id);
            this.$emit("eventViewPostRemoveFromPool", id)
            this.loading = false;
        },

        // events
        eventPostAddedInPool(id, index) {
            this.$emit("eventViewPostAddedInPool", id)
            this.posts[index].is_pooled = true;
        },

        // no need to send index to main
        // index is for this view
        eventPostRemoveFromPool(id, index) {
            this.$emit("eventViewPostRemoveFromPool", id)
            this.posts[index].is_pooled = false;
        },

        // Go Source
        eventGoSource(index) {
            subscriber.setNewsScroll(window.scrollY);
            this.$router.push({path: "/main/source/" + this.posts[index].source_ref})
        },


        // Events

        // Vote
        // in this case only trash button activate the event
        eventVote(...[,index,stream]) {
            
            // if pooled => remove
            this.removeFromPool(this.posts[index].id)
            
            // remove from posts
            this.posts.splice(index, 1)
            this.total --;
            
            if (stream == 'single') {
                this.eventCloseSingleDialog();
            }

        },

        // Go tools Tags
        eventGoTags(index) {
            subscriber.setNewsScroll(window.scrollY);
            this.$router.push({path: "/main/tools_post_tags_tab/" + this.posts[index].id})
        },

        // Go tools Loot
        eventGoLoot(index) {
            subscriber.setNewsScroll(window.scrollY);
            this.$router.push({path: "/main/tools_post_loot_tab/" + this.posts[index].id})
        },

        // Go tools Corpus
        eventGoCorpus(index) {
            subscriber.setNewsScroll(window.scrollY);
            this.$router.push({path: "/main/tools_post_corpus_tab/" + this.posts[index].id})
        },

        //* Dialog Single
        eventOpenSingleDialog(index) {
            this.dialogSingle = true;
            this.dialogSinglePost = this.posts[index];
            this.dialogSingleIndex = index;
            subscriber.setNewsDialog(true);
            subscriber.setNewsSingle(this.posts[index]);
            subscriber.setNewsIndex(index);
            subscriber.setNewsScroll(window.scrollY);
            this.scrolling = window.scrollY;
        },

        eventCloseSingleDialog() {
            this.dialogSingle = false;
            this.dialogSinglePost = null;
            this.dialogSingleIndex = null;
            subscriber.setNewsDialog(false);
            subscriber.setNewsSingle(null);
            subscriber.setNewsIndex(0);
            window.scrollTo(0,this.scrolling);
        },

        sheetPostColor(index) {
            switch (this.posts[index].source_ref) {
                case "FR046":
                    return "deep-purple accent-2"
                case "ES010":
                    return "purple accent-2"
                default:
                    return "accent"
            }
            
        }
    },

    computed: {
       
    }
}

</script>

<style></style>