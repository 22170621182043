<template>
    <div>
    
        <v-card flat color="Background" key="deck-tab" class="pt-2">


            <!-- Deck Parameters -->
            <Parameters key="deck-parameters"
                :clusters="clusters"
                :delay="delay"
                :max_posts_count="max_posts_count"
                :new_loading="new_loading"
                :last_loading="last_loading"
                @eventUpdateParameters="eventUpdateParameters"
                @eventFetchNewDeck="fetchNewDeck"
                @eventFetchLastPosts="fetchLastPosts"
                class="mx-2"
            />

            <!-- loading -->
            <v-progress-linear 
                indeterminate 
                color="success"
                :active="loading"
            ></v-progress-linear>

            <!-- error -->
            <v-card v-if="error && error != 'empty'" flat color="transparent" key="deck-error-message">
                <!-- error msg -->
                <v-card-text class="ml-5 mt-2 error--text">API Error: {{ error }}</v-card-text>
            </v-card>

            <!-- empty decks message -->
            <v-card flat key="deck-empty-message">
                <v-card-text v-if="error == 'empty'" class="text-center">Deck empty. Check Standby</v-card-text>
            </v-card>

            <!-- Clusters -->
            <draggable v-if="clusters && clusters.length > 0"
                v-model="clusters"
                group="clusters"
                @end="onDragEnd"
                key="draggable-clusters"
            >
                <v-card v-for="(cluster, cl_index) in clusters" :key="'cl_' + cl_index" class="">
                    
                    <span v-if="parameters.continent == 'all' || cluster.continent_name == parameters.continent">
                        <!-- Cluster -->        
                        <v-sheet :key="'sheet-cluster-' + cl_index"
                            outlined rounded
                            v-if="(cluster.posts.length > 1 && cluster.is_out == parameters.see_out) || (cluster.posts.length == 1 && cluster.posts[0].is_out == parameters.see_out)"
                            :color="sheetClusterColor(cl_index)" 
                            class="ma-2"
                        >
                            <v-card
                                flat 
                                class="pb-1" 
                                color=""
                                :key="'cluster-' + cl_index"
                            >
                                
                                <!-- cluster header-->
                                <ClusterHeader v-if="cluster.posts.length > 0" :key="'cluster-header-' + cl_index"
                                    :clusters="clusters"
                                    :cluster="cluster"
                                    :cl_index="cl_index"
                                    :tags_list="tags_list"
                                    @eventShowCluster="eventShowCluster"
                                    @eventSwitchDeckOut="eventSwitchDeckOut"
                                    @eventChangeContinent="eventChangeContinent"
                                    @eventRemoveTagFromCluster="eventRemoveTagFromCluster"
                                    @eventRemoveLootFromCluster="eventRemoveLootFromCluster"
                                    @eventMergeClusters="eventMergeClusters"
                                    @eventOpenPublisherDialog="eventOpenPublisherDialog"
                                    @eventSortClusters="sortClusters"
                                />

                                <!-- Posts -->
                                <draggable v-if="cluster.show" :list="cluster.posts" group="posts" @change="onDragEnd" key="draggable-cluster-posts">
                                    
                                    <div v-for="(post, p_index) in cluster.posts" :key="'cl' + cl_index + '-p_' + post.id">
                                        
                                        <!-- Post -->
                                        <v-sheet outlined class="mx-1 mt-1" 
                                            v-if="post.is_out == parameters.see_out"
                                            :color="sheetPostColor(cl_index, p_index)"
                                        >
                                            <v-card flat color="" class="d-flex" key="cluster-post-and-widget">
                                                <!-- cluster widget -->
                                                <ClusterPostWidget :key="'widget_' + cl_index + '-p_' + post.id"
                                                    :post="post"
                                                    :cl_index ="cl_index"
                                                    :p_index="p_index"
                                                    :cluster_posts_count="cluster.posts.length"
                                                    @eventPostInNewCluster="eventPostInNewCluster"
                                                />

                                                <ClusterPost :key="'post_' + cl_index + '-p_' + post.id"
                                                    :post="post"
                                                    :cl_index ="cl_index"
                                                    :p_index="p_index"
                                                    :color="sheetPostColor(cl_index, p_index)"
                                                    @eventGoPublisher="eventGoPublisher"
                                                    @eventGoLoot="eventGoLoot"
                                                    @eventGoTags="eventGoTags"
                                                    @eventVote="eventVote"
                                                    @openClusterPostsSingleDialog="openClusterPostsSingleDialog"
                                                />
                                            </v-card>
                                        </v-sheet>
                                    </div>
                                </draggable>
                            </v-card>
                        </v-sheet>
                    </span>
                </v-card>
            </draggable>
        </v-card>


        <!-- Dialogs -->
        <v-dialog v-model="dialogs" key="deck-dialogs" fullscreen overlay-opacity="">
            <v-card flat color="" key="deck-dialogs-card">
                
                <!-- Cluster Post Single -->
                <DialogClusterSingle
                    v-if="cluster_single_dialog"
                    :cluster_single="cluster_single"
                    @eventCloseClusterPostDialog="eventCloseClusterPostDialog"
                    @eventGoPublisher="eventGoPublisher"
                    @eventGoTags="eventGoTags"
                    @eventGoLoot="eventGoLoot"
                    @eventVote="eventVote"
                    @eventGoSource="eventGoSource"
                    :color="sheetPostColor(cluster_single.cl_index, cluster_single.p_index)"
                />

                <!-- publisher Dialog -->
                <DialogPublisher
                    v-if="publisher_dialog"
                    :cluster="publisher.cluster"
                    :cl_index="publisher.cl_index"
                    :from_calendar="from_calendar"
                    @eventClosePublisherDialog="eventClosePublisherDialog"
                />
            </v-card>
        </v-dialog>

        <!-- snackbar no last posts-->
        <v-snackbar centered v-model="last_snackbar" timeout="1000" color="blue">
            <span class="font-weight-bold Dialog--text">No Last Posts</span>
            <template v-slot:action="{ attrs }">
                <v-btn
                    small
                    class="Dialog--text"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    {{ $i18n.t("close") }}
                </v-btn>
            </template>
        </v-snackbar>

    </div>
</template>

<script>
import deck from "@/common/deck";
import Draggable from 'vuedraggable'
import Parameters from './DeckParameters.vue'
import ClusterHeader from './Cluster/DeckClusterHeader.vue'
import ClusterPostWidget from './Cluster/DeckClusterPostWidget.vue'
import ClusterPost from './Cluster/DeckClusterPost.vue'

import DialogClusterSingle from './DialogClusterSingle.vue'
import DialogPublisher from './DialogPublisher.vue'

export default {


    name: 'DeckMain',

    components: {
        Parameters,
        Draggable,
        ClusterHeader,
        ClusterPostWidget,
        ClusterPost,

        DialogClusterSingle,
        DialogPublisher,
    },

    data: () => ({
        
        loading: false,
        error: null,

        new_loading: false,
        last_loading: false,
        last_snackbar: false,

        // header
        language: null,
        country: null,

        // Parameters
        delay: 0,
        max_posts_count: 0,
        parameters: null,

        // paging
        scrolling: 0,

        //* Deck
        clusters: [],
        post_ids: [],

        //* Persistence
        storage_clusters : null,

        //* widgets
        //- tags widget
        tags_list:[],

        // Dialogs
        dialogs: false,

        //cluster post
        cluster_single_dialog: false,
        cluster_single: {
            cl_index: null,
            p_index: null,
            post: null,
        },

        // publisher
        publisher_dialog: false,
        publisher: {
            cluster: null,
            cl_index: null,
        },

        from_calendar: false,
    }),

    created() {

        // Header init
        this.language = deck.retriveLanguage();
        this.country = deck.retriveCountry();

        // init
        this.storage_clusters = null,
        this.parameters = deck.retriveParameters();
    
        // widgets Tags needs the full list to add tags
        this.fetchTags()

        // localStorage
        this.storage_clusters = JSON.parse(localStorage.getItem('clusters'));
        
        if (!this.storage_clusters || this.storage_clusters.length == 0 ) {
            console.log("not deck in storage, fetching deck via api")
            this.fetchNewDeck()
        } else {
            setTimeout(
                () => this.patchClusters()
                .then(this.fetchUpdatedPosts())
                .then( () => window.scrollTo(0, this.scrolling)),
                1000
            )
        }


        // router navigation back from calendar
        if (this.$route.params.post_id) {
            console.log("params found pid", this.$route.params.post_id);
            this.buildClusterFromPostIds([this.$route.params.post_id]);
            this.publisher_dialog = true;
            this.dialogs = true;
            this.from_calendar = true;
        } else if (this.$route.params.post_ids) {
            console.log("params found pids", this.$route.params.post_ids);
            this.buildClusterFromPostIds(this.$route.params.post_ids);
            this.publisher_dialog = true ;
            this.dialogs = true;
            this.from_calendar = true;
        }


    },

    methods: {

        //* Dialogs

        // Cluster Post
        openClusterPostsSingleDialog(cl_index, p_index) {
            console.log("openClusterPostsSingleDialog", cl_index, p_index);
            this.dialogs = true;
            this.cluster_single_dialog = true;
            this.cluster_single.post = this.clusters[cl_index].posts[p_index];
            this.cluster_single.cl_index = cl_index;
            this.cluster_single.p_index = p_index;
            this.scrolling = window.scrollY;
            deck.setClusterDialog(true);
            deck.setClusterSingle(this.clusters[cl_index].posts[p_index]);
            deck.setClusterIndex(cl_index);
            deck.setClusterPostIndex(p_index);
            deck.setScroll(window.scrollY);
        },

        eventCloseClusterPostDialog() {
            console.log("eventCloseClusterPostDialog");
            this.dialogs = false;
            this.cluster_single_dialog = false;
            this.cluster_single.post = null;
            this.cluster_single.cl_index = 0;
            this.cluster_single.p_index = 0;
            deck.setClusterDialog(false);
            deck.setClusterSingle(null);
            deck.setClusterIndex(0);
            deck.setClusterPostIndex(0);
            this.scrolling = window.scrollY;
        },

        eventGoPublisher(cl_index, p_index) {
            console.log("eventGoPublisher", this.clusters[cl_index].posts[p_index].id)
            deck.setScroll(window.scrollY);
            this.$router.push({path: "/main/publisher/" + this.clusters[cl_index].posts[p_index].id})
        },

        eventGoTags(cl_index, p_index) {
            deck.setScroll(window.scrollY);
            this.$router.push({path: "/main/tools_post_tags_tab/" + this.clusters[cl_index].posts[p_index].id})
        },

        eventGoLoot(cl_index, p_index) {
            deck.setScroll(window.scrollY);
            this.$router.push({path: "/main/tools_post_loot_tab/" + this.clusters[cl_index].posts[p_index].id})
        },

        eventGoSource(cl_index, p_index) {
            deck.setScroll(window.scrollY);
            const routeData = this.$router.resolve({ path: "/main/source/" + this.clusters[cl_index].posts[p_index].source_ref });
            window.open(routeData.href, '_blank');
        },

        eventMergeClusters(from_cl_index, to_cl_index) {
            console.log("eventMergeClusters", from_cl_index, to_cl_index);
            for (let p of this.clusters[from_cl_index].posts) {
                this.clusters[to_cl_index].posts.push(p)
            }
            this.clusters.splice(from_cl_index,1);
        },


        // publisher
        eventOpenPublisherDialog(cl_index) {
            this.dialogs = true;
            this.publisher_dialog = true;
            this.publisher.cluster = this.clusters[cl_index];
            this.publisher.cl_index = cl_index;
        },

        eventClosePublisherDialog() {
            console.log("eventClosePublisherDialog tab1")
            this.dialogs = false;
            this.publisher_dialog = false
            this.publisher.cluster = null;
            this.publisher.cl_index = null;
            this.fetchUpdatedPosts();
        },

        ///* API
        fetchNewDeck: async function () {
            console.log("fetch new deck");
            this.new_loading = true;
            this.error = null;
            this.delay = 0;

            this.clusters = [],
            this.post_ids =[];

            let payload =  {
                hour: this.parameters.daily_batch_hour,
                doc_epsilon:  this.parameters.doc_epsilon / 10,
                tags_loot_epsilon:  this.parameters.tags_loot_epsilon / 10,
                min_points: this.parameters.min_points,
                day_offset: this.parameters.day_offset,
                publisher_model_ref: this.parameters.publisher_model_ref,
            }

            let filter = (
                this.languageApiFilter() +
                this.countryApiFilter()
            );

            // reset deck storage
            localStorage.removeItem('clusters');

            try {
                let res = await this.$api.post("/profile/deck/fetch" + filter, payload);
                for (let c of res.data.clusters){
                    let posts = []
                    for (let dp of c.deck_posts) {
                        let p = dp.post
                        p.is_out = false;
                        p.is_community = dp.is_community;
                        posts.push(p);
                    }
                    c.posts = posts
                    c.is_out = false;
                    c.show = false;
                    this.clusters.push(c);
                }
                // delay
                this.delay = Math.round(1000 * res.data.delay);
                // Max posts count for query
                this.max_posts_count = res.data.max_posts_count

            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.updateClusters();
                this.new_loading = false;
            }
        },

        fetchUpdatedPosts: async function() {

            console.log("fetch updated posts")

            // need to rebuild post ids
            this.buildPostIds();
            
            // reset deck storage
            localStorage.removeItem('clusters');
            
            this.loading = true;
            this.error = null;
            
            let payload =  {
                ids: this.post_ids,
            }

            try {
                let res = await this.$api.post("/profile/deck/updated", payload);
                let updated_posts_map = res.data
                let updated_clusters = []
                for (let c of this.clusters) {
                    let updated_cluster = {
                        is_out: c.is_out,
                        show: c.show,
                        posts: [],
                        continent_name: c.continent_name,
                        continent_order: c.continent_order,
                        continent_iso3: c.continent_iso3,
                        is_doc_clustered : c.is_doc_clustered,
                        is_tags_loot_clustered: c.is_tags_loot_clustered,
                    };
                    for (let p of c.posts) {
                        let up = updated_posts_map[p.id].post;
                        up.is_out = p.is_out;
                        up.is_community = updated_posts_map[p.id].is_community;
                        updated_cluster.posts.push(up)
                    }
                    updated_clusters.push(updated_cluster)
                }
                this.clusters = updated_clusters;
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.updateClusters();
                this.loading = false;
            }
            console.log("clusters updated")

        },

        fetchLastPosts: async function () {
            console.log("fetch last posts");
            this.last_loading = true;
            this.error = null;

            let payload =  {
                ids: this.post_ids,
                hour: this.parameters.daily_batch_hour,
                doc_epsilon:  this.parameters.doc_epsilon / 10,
                tags_loot_epsilon:  this.parameters.tags_loot_epsilon / 10,
                min_points: this.parameters.min_points,
                day_offset: this.parameters.day_offset,
                publisher_model_ref: this.parameters.publisher_model_ref,
            }

            let filter = (
                this.languageApiFilter() +
                this.countryApiFilter()
            );

            try {
                let res = await this.$api.post("/profile/deck/fetch" + filter, payload);
                for (let c of res.data.clusters){
                    let posts = []
                    for (let dp of c.deck_posts) {
                        let p = dp.post
                        p.is_out = false;
                        p.is_community = dp.is_community;
                        posts.push(p);
                    }
                    c.posts = posts
                    c.is_out = false;
                    c.show = false;
                    this.clusters.push(c);
                }
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                if (data.message == "last empty") {
                    this.last_snackbar = true;
                } else {
                    this.error = data.message;
                }
            } finally {
                this.updateClusters();
                this.last_loading = false;
            }
        },


        // when we are building a design cluster for editing a evney in aclendar or publishing a post from news
        // publisher dialog would be open
        buildClusterFromPostIds: async function(pids) {
            
            this.loading = true;
            this.error = null ;
            let cluster = {
                posts:[],
            }
            for (let id of pids) {
                try {
                    let res = await this.$api.get("/posts/" + id);
                    cluster.posts.push(res.data)
                } catch (e) {
                    let data = (e.response || {}).data || "unknown error";
                    this.error = data.message;
                }
            }

            this.loading =false;
            this.publisher.cluster = cluster;
            this.publisher.cl_index = -1;
        },

       
        //* HEADER

        // Header filters
        countryApiFilter() {
            if (this.country != "all") {
                return "?country=" + this.country
            }
            return ""
        },

        languageApiFilter() {
            if (this.language != "all") {
                return "&language=" + this.language
            }
            return ""
        },

        //* PARAMETERS
        eventUpdateParameters(params) {
            console.log("eventUpdateParameters", params.continent)
            this.parameters = params;
        },


        //* DRAGGABLE

        // remove clusters if only one post left
        onDragEnd() {
            // Check if the dragged cluster has no posts left
            console.log("onDragEnd")
            
            // PATCH: only option doing the job
            // difficult to achieve same results with draggable...
            // get rid of cluster with empty posts
            let clusters = []
            for (let c of this.clusters) {
                if (c.posts.length > 0) {
                    clusters.push(c)
                }
            }  
            this.clusters = clusters
            
            this.updateClusters();
        },


        //* CLUSTERS

        // needs to return a promise to be followed by then() for the scrolling
        // as a result async func
        async patchClusters() {
            this.clusters = [];
            this.clusters = this.storage_clusters;
            // console.log("patch over")
        },

        // global updating
        updateClusters() {
            
            this.buildPostIds()
            
            // order by continent
            console.log("updating clusters")
            this.sortClusters()
            
            // deck storage
            localStorage.setItem('clusters', JSON.stringify(this.clusters));
            
        },

        // posts ids
        buildPostIds() {
            this.post_ids = []
            for (let c of this.clusters) {
                for (let p of c.posts) {
                    this.post_ids.push(p.id);
                }
            }
        },

        //- order clusters by continent
        sortClusters() {

            // by continent
            this.clusters = this.clusters.sort((a, b) => a.continent_order - b.continent_order);

            // by post content no html length
            this.clusters.forEach(
                cluster => {
                    cluster.posts = cluster.posts.sort((a, b) => {
                        // Remove HTML tags and count the words for both posts
                        let wordCountA = a.content.replace(/<[^>]+>/g, "").split(" ").length;
                        let wordCountB = b.content.replace(/<[^>]+>/g, "").split(" ").length;

                        // Sort by word count in descending order
                        return wordCountB - wordCountA;
                    });
                }
            );

        },

        // new cluster form one single post
        eventPostInNewCluster(cl_index, p_index, post) {
            console.log("eventPostInNewCluster",cl_index,p_index);
            if(this.clusters[cl_index].posts.length > 1) {
                // create a cluster following the exited one
                this.clusters.splice(cl_index+1, 0, {
                    posts: [post],
                    show: true,
                    is_out: this.parameters.see_out,
                    continent_name: this.clusters[cl_index].continent_name,
                    continent_order: this.clusters[cl_index].continent_order,
                })

                // remove post from the old cluster
                this.clusters[cl_index].posts.splice(p_index,1)

                this.updateClusters();
            }
        },

        eventShowCluster(cl_index) {
            this.clusters[cl_index].show = !this.clusters[cl_index].show;
            this.updateClusters();
        },

        eventSwitchDeckOut(cl_index) {
            this.clusters[cl_index].is_out = !this.clusters[cl_index].is_out;
            let posts = [];
            for (let p of this.clusters[cl_index].posts) {
                p.is_out = this.clusters[cl_index].is_out;
                posts.push(p);
            }
            this.clusters[cl_index].posts = posts;

            this.updateClusters();
        },

        eventChangeContinent(selected_continent, order, cl_index) {
            console.log("eventChangeContinent", selected_continent, order, cl_index);
            this.clusters[cl_index].continent_name = selected_continent;
            this.clusters[cl_index].continent_order = order;
            this.updateClusters();
        },

        eventRemoveTagFromCluster(cl_index, ref) {
            console.log("eventRemoveTagFromCluster", cl_index, ref);
            let posts = [];
            for (let p of this.clusters[cl_index].posts) {
                let tags = [];
                for (let t of p.tags) {
                    if (t.ref != ref) {
                        tags.push(t)
                    }
                }
                p.tags = tags
                posts.push(p)
            }
            this.clusters[cl_index].posts = posts
            this.updateClusters();
        },

        eventRemoveLootFromCluster(cl_index, ref) {
            console.log("eventRemoveLootFromCluster", cl_index, ref);
            let posts = [];
            for (let p of this.clusters[cl_index].posts) {
                let loot = [];
                for (let l of p.loot) {
                    if (l.tag_ref != ref) {
                        loot.push(l)
                    }
                }
                p.loot = loot
                posts.push(p)
            }
            this.clusters[cl_index].posts = posts
        },


        //* WIDGETS
        fetchTags: async function() {
            console.log("fetching tags");
    
            this.error = null;
            this.tags_list = [];
            try {
                let res = await this.$api.get("/tags");
                this.tags_list = res.data;
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            }
        },
        
        //* COLORS
        sheetClusterColor(cl_index) {
            let c = ""
            for (let p of this.clusters[cl_index].posts) {
                if (p.is_error) {
                    c = "orange";
                    return c
                    
                }
                if (p.is_published) {
                    c = "blue lighten-1";
                    return c
                }
                if (p.is_scheduled) {
                    c = "lime accent-2";
                    return c
                }

                // crimorg
                if (this.clusters[cl_index].posts.length == 1) {
                    if (p.source_ref == "FR046") {
                        c =  "deep-purple accent-2"
                        return c
                    } else if (p.is_community) {
                        // is community
                        c =  "purple accent-2";
                        return c
                    }
                }
               
            }

            if(!this.clusters[cl_index].is_out) {
                c = "success";
            } else {
                c = "amber";
            } 
            return c
        },

        sheetPostColor(cl_index, p_index) {

            if (this.clusters[cl_index].posts[p_index]) {

                let p = this.clusters[cl_index].posts[p_index];

                if (p.is_error) {
                    return "orange"   
                }

                if (p.is_published) {
                    return "blue lighten-1"
                }

                if (p.is_scheduled) {
                    return "lime accent-2"
                }
                
                if (p.source_ref == "FR046") {
                    return "deep-purple accent-2"
                } else if (p.is_community) {
                    return "purple accent-2"
                }
            }  
            
            if (this.clusters[cl_index].is_out) {
                return "amber"
            } else {
                return "success"
            }
            
        },


        eventVote(cl_index, p_index) {
            console.log("eventVote", cl_index, p_index)
            if (this.clusters[cl_index].posts.length ==  1 ) {
                this.clusters.splice(cl_index,1)
            } else {
                this.clusters[cl_index].posts.splice(p_index,1);
            }

           this.updateClusters();
           this.eventCloseClusterPostDialog();
        },

    },

    computed: {

    }

}
</script>

<style>

</style>