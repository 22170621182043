<template>
    <div>
        <v-sheet tile rounded outlined class="" :color="headerColor">
            <v-card tile flat outlined class="d-flex py-1" key="cluster-header" :color="header_background">
                <v-card flat outlined color="transparent" width="82px" class="px-1">
                    
                    <!-- SHOW/hide cluster posts  -->
                    <v-btn v-if="cluster.posts.length > 1"  @click="switchShowCluster" x-small class="font-weight-bold Dialog--text ml-1 mt-0" :color="headerColor">
                        #{{cl_index}}·{{deckClustersCount}}
                    </v-btn>
                    <v-btn v-else @click="switchShowCluster" x-small outlined class="font-weight-bold ml-1 mt-0" :color="headerColor">
                        #{{cl_index}}·{{deckClustersCount}}
                    </v-btn>

                    <!-- switch deck  -->
                    <v-btn v-if="cluster.is_out" @click="switchDeckOut" x-small class="Dialog--text font-weight-bold ml-1 mt-2" :color="headerColor">
                       <v-icon>mdi-arrow-left-bold</v-icon>
                    </v-btn>
                    <v-btn v-else @click="switchDeckOut" x-small rounded class="Dialog--text font-weight-bold ml-1 mt-2" :color="headerColor">
                       <v-icon>mdi-arrow-right-bold</v-icon>
                    </v-btn>
                    
                    <!-- drag up/down button -->
                    <v-btn x-small class="mt-2 ml-1" color="grey"><v-icon color="Dialog">mdi-drag</v-icon></v-btn>

                    <!-- imgs/posts count -->
                    <v-btn x-small text class="mt-1 lime--text">{{buildClusterImgUrls.length}}/{{noHtmlContent(cluster.posts[0].content).length}}</v-btn>

                    <!-- Go Cluster Publisher -->
                    <v-btn @click="eventOpenPublisherDialog" x-small class="Dialog--text mt-1 ml-1" color="lime accent-2"><v-icon >mdi-publish</v-icon></v-btn>

                    <!-- Go Cluster Eventer -->
                    <!-- <v-btn v-if="hasLoot" x-small class="mt-2 ml-1" color="pink"><v-icon class="">mdi-alpha-e</v-icon></v-btn> -->
                </v-card>
                

                <v-divider vertical class="ml-2"></v-divider>
                
                <!-- avatar -->
                <v-avatar v-if="avatar_url.length > 0"
                    rounded=""
                    size="140"
                    class="ml-2"
                >
                    <v-img v-if="cluster.posts.length > 0" :src="avatar_url" :key="avatar_url"></v-img>
                </v-avatar>
               
               <v-card flat class="" color="transparent" width="100%" key="title-sources-translation">
                    
                    <!-- title -->
                    <v-card-title @click="switchShowCluster" style="cursor: pointer"  class="wordbreak text-body-1 font-weight-bold py-1 pb-0">
                        {{buildTitle}}
                    </v-card-title>

                    <v-card-actions>
                            <v-btn v-if="cluster.posts.length > 1" x-small class="font-weight-bold Dialog--text" color="lime">{{ cluster.posts.length }}</v-btn>
                            <v-btn x-small @click="merger_dialog = true" :color="headerColor" class="Dialog--text font-weight-bold">Merge</v-btn>
                            <!-- clustering methods-->
                            <v-btn x-small v-if="cluster.is_doc_clustered" class="font-weight-bold" color="blue">Doc</v-btn>
                            <v-btn x-small v-if="cluster.is_tags_loot_clustered" class="font-weight-bold" color="purple">TL</v-btn>
                            <!--Tags Loot filtering-->
                            <v-btn x-small v-if="cluster.tags_loot_filter_ref > 0" class="font-weight-bold" color="warning"> Fileter #{{cluster.tags_loot_filter_ref}}</v-btn>

                            <!-- Sources Widget-->
                            <SourcesWidget :posts="cluster.posts" :color="headerColor" class="ml-2"/>
                            <v-spacer></v-spacer>
                            <!-- Translation Widget-->
                            <TranslationWidget
                                :post="cluster.posts[0]"
                                tab="cluster"
                                stream="post"
                                class="ml-4"
                                @eventTranslation="eventTranslation"
                            ></TranslationWidget>
                    </v-card-actions>

                    <v-divider></v-divider>

                    <v-card-actions class="py-0 pb-0" key="tags-continent-loot">
                        <TagsWidget 
                            :cluster="cluster"
                            :cl_index="cl_index"
                            :tags_list="tags_list"
                            @eventRemoveTagFromCluster="eventRemoveTagFromCluster"
                            @eventSortClusters="eventSortClusters"
                            class="ml-2"
                        />
                        <v-spacer></v-spacer>
                        <ContinentWidget 
                            :cluster ="cluster"
                            :cl_index="cl_index"
                            :color="headerColor"
                            @eventChangeContinent="eventChangeContinent" 
                            class="mb-1"
                        />
                    </v-card-actions>
                    <v-card-actions class="py-0">
                        <LootWidget 
                            :cluster ="cluster"
                            :cl_index="cl_index"
                            :tags_list="tags_list"
                            @eventRemoveLootFromCluster="eventRemoveLootFromCluster"
                            class="ml-2"
                        />
                    </v-card-actions>
                </v-card>

            </v-card>
        </v-sheet>

        <v-dialog v-model="merger_dialog" width="400">
            <v-sheet rounded outlined color="success">
                <v-card v-if="clusters" flat class="pa-2">
                    <v-btn small v-for="(cluster, n) in clusters" :key="'cluster_' + n" @click="eventMergeClusters(n)" :color="clusterColor(n)" class="font-weight-bold ml-1 mb-1">{{n}}</v-btn>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small
                            :color="headerColor"
                            text
                            @click="merger_dialog = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-sheet>
        </v-dialog>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TranslationWidget from '../../Translations/TranslationWidget.vue'
import SourcesWidget from './DeckWidgetSources.vue'
import TagsWidget from './DeckWidgetTags.vue'
import LootWidget from './DeckWidgetLoot.vue'
import ContinentWidget from './DeckWidgetContinent.vue'

export default {

    name: 'ClusterHeader',

    props: ['clusters', 'cluster', 'cl_index','tags_list'],

    data: () => ({
       
        show_cluster: true,
        is_cluster_out: false,

        header_background: "grey darken-4",

        // Merger
        merger_dialog: false,
        dialog_target_cluster: 0,
    }),

    components: {
        TranslationWidget,
        SourcesWidget,
        TagsWidget,
        LootWidget,
        ContinentWidget,
    },

    created() {
        if (this.cluster) {
            this.show_cluster = this.cluster.show;
            this.is_cluster_out = this.cluster.is_out;
        }
    },




    methods: {

        switchShowCluster() {
            this.show_cluster = !this.show_cluster
            this.$emit("eventShowCluster", this.cl_index)
        },

        switchDeckOut() {
            this.$emit("eventSwitchDeckOut", this.cl_index)
        },

        eventRemoveTagFromCluster(cl_index, ref) {
            this.$emit("eventRemoveTagFromCluster", cl_index, ref)
        },

        eventRemoveLootFromCluster(cl_index, ref) {
            this.$emit("eventRemoveLootFromCluster", cl_index, ref)
        },

        eventSortClusters() {
            this.$emit("eventSortClusters")
        },

        eventMergeClusters(n) {
            this.$emit("eventMergeClusters", this.cl_index, n)
            this.merger_dialog = false;
        },

        //* Translations
        eventTranslation(output_language, content, description, title) {
            console.log("eventTranslation", output_language, content.length, description.length,title)
            if (this.cluster.posts[0].translations.length > 0) {
                for (let tr of this.cluster.posts[0].translations) {
                    if (tr.output_language == output_language) {
                        if (title.length > 0) {
                            tr.title = title
                        }
                        if (description.length > 0) {
                            tr.description = description;
                        }
                        tr.content = content;
                    }
                }
            }
        },

        eventChangeContinent(selected_continent, order){
            this.$emit("eventChangeContinent", selected_continent, order, this.cl_index)
        },

        //* Publisher
        eventOpenPublisherDialog() {
            this.$emit("eventOpenPublisherDialog", this.cl_index)
        },

        noHtmlContent(text) {
            return text.replace(/<[^>]*>/gi, '');
        },


        clusterColor(n) {
           
            if (this.clusters && this.clusters.length > 0) {
                if (n == this.cl_index ) {
                    return "error"
                } else if (this.clusters[n].is_out) {
                    return "grey darken-4"
                } else {
                    return "success"
                }
            } else {
                return "grey"
            }
        },

    },

    computed: {

        ...mapGetters({
            trans_settings: 'getTransSettings',
        }),

        avatar_url() {
            return this.cluster.posts.length > 0 ? this.cluster.posts[0].img_url : '';
        },

        // needer for cluster header
        deckClustersCount() {
            return this.clusters.filter(c => !c.is_out).length;
        },

        hasLoot() {
            if (this.cluster.posts) {
                for (let p of this.cluster.posts) {
                    if (p.loot) {
                        return true
                    }
                }
            }
            return false
        },


        buildTitle() {
            let title = ""
            if (this.cluster.posts.length > 0) {
                title = this.cluster.posts[0].title;

                if (this.trans_settings && this.trans_settings[this.cluster.posts[0].language] && this.trans_settings[this.cluster.posts[0].language].available.length > 0) {
                    for (let ol of this.trans_settings[this.cluster.posts[0].language].available) {
                        if (ol.trans_active) {
                            if (this.cluster.posts.length > 0 && this.cluster.posts[0].translations) {
                                for (let trans of this.cluster.posts[0].translations) {
                                    if (trans.output_language == ol.iso) {
                                        if (trans.title.length > 0) {
                                            title = "[" + trans.title.trim() + "]";
                                        }
                                    }
                                }
                            }
                        } 
                    }
                    
                }
            }
            return title
        },



        buildClusterImgUrls() {
            let cl_img_urls = [];
            let regex = /<img[^>]*src="([^"]*)"/g;
            for (let p of this.cluster.posts) {
                let match;
                while ((match = regex.exec(p.content))) {
                    cl_img_urls.push(match[1]);
                }

                // from feeder
                if (p.img_url.length > 0) {
                    cl_img_urls.push(p.img_url)
                }

                // remove duplicates
                cl_img_urls = [...new Set(cl_img_urls)];
            }
            return cl_img_urls
        },


        headerColor() {
            for (let p of this.cluster.posts) {
                if (p.is_error) {
                    return "orange";
                }
                if (p.is_published) {
                    return "blue lighten-1";
                    
                }
                if (p.is_scheduled) {
                    return "lime accent-2";
                }
            }

            if (this.cluster.posts.length == 1) {
                if (this.cluster.posts[0].source_ref == "FR046") {
                    return "deep-purple accent-2"
                } else if (this.cluster.posts[0].is_community) {
                    return "purple accent-2"
                } 
            }


            if (this.cluster.is_out) {
                return "amber"
            } else {
                return "success"
            }
        },

        
    },

}
</script>

<style>
.wordbreak {
    word-break: normal;
}
</style>