<template>
    <div>
        <v-card key="Basic.vue">
            <!-- loading -->
            <v-progress-linear
                indeterminate
                color="accent"
                :active="loading">
            </v-progress-linear>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-text-field
                    dense
                    v-model="query"
                    @keydown.enter.prevent="launchBasicSearch"
                    outlined
                    hide-details
                    clearable
                    @click:clear="resetBasicSearch"
                    class="mb-3"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-switch v-model="switchCO" label="CO ?" @change="launchBasicSearch"></v-switch>
                <v-spacer></v-spacer>
                <span v-if="total">{{total}} posts</span>
                <v-spacer></v-spacer>
                <span v-if="page > 1" class="ml-5">[<v-icon small color="warning" class="mx-1">mdi-alert</v-icon>page {{searchStore.page}} in store]</span>
                <v-spacer></v-spacer>
            </v-card-actions>

           

            <!-- error msg -->
            <span v-if="error && error != 'empty'" class="ml-5 mt-2 error--text">API Error: {{ error }}</span>
            
            <!-- no post msg -->
            <v-card v-if="error == 'empty'" flat color="transparent">
                <v-card-text class="text-center">{{$i18n.t("noMorePost")}}</v-card-text>
            </v-card>

            <!-- Pagination -->
            <div v-if="total > options.itemsPerPage" class="text-center my-3">
                <v-pagination
                v-model="page"
                @input="inputPage"
                :length="paginationLength"
                :total-visible="7"
                color="accent darken-3"
                circle>
                </v-pagination> 
            </div>

            <!-- TRANSLATIONS -->
            <TranslationsSelectWidget/>

            <v-sheet 
            outlined rounded 
            v-for="(post, index) in posts" :key="'post_'+post.id" 
            class="my-2 mx-3" :color="postColor(post)">
                <v-card flat class="d-flex">
                    <!-- Widgets -->
                    <v-card class="mt-5" color="transparent">
                        <PublisherWidget 
                            :post="post"
                            @eventGoFramer="eventGoFramer"
                        ></PublisherWidget>
                        <VoteWidget v-if="isUserProfileTrainer"
                            @eventVote="eventVote"
                            :post="post" 
                            :index="index" 
                            stream="post" 
                            tab="searcher"
                        ></VoteWidget>
                    </v-card>
                    <!-- Post -->
                    <Post 
                        :post="post" :index="index" 
                        :key='"p" + post.id'
                        @eventGoSingle="eventOpenSingleDialog"
                    ></Post>
                </v-card>
            </v-sheet>

            <!-- Pagination -->
            <div v-if="total > options.itemsPerPage" class="text-center my-3">
                <v-pagination
                v-model="page"
                @input="inputPage"
                :length="paginationLength"
                :total-visible="7"
                color="accent darken-3"
                circle>
                </v-pagination> 
            </div>

            <!-- DIALOG SINGLE -->
            <v-dialog 
                v-model="dialogSingle"
                key="basic-searcher-dialog-single"
                fullscreen
            >  
                <!-- v-card needed for triggering fullscreen-->    
                <v-card>  
                    <Single v-if="dialogSingle"
                        :single="dialogSinglePost" 
                        :index="dialogSingleIndex"
                        :isUserProfileTrainer="isUserProfileTrainer"
                        @eventCloseSingleDialog="eventCloseSingleDialog"
                        colorView="accent"
                        tab="searcher"
                    ></Single>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import searcher from "@/common/searcher";
import Post from './SearcherPost'
import Single from './SearcherDialogSingle'
import VoteWidget from '../../Vote/VoteWidget.vue'
import TranslationsSelectWidget from '../../Translations/TranslationsSelectWidget.vue'
import PublisherWidget from '../../Deck/PublisherPostWidget'

import { mapGetters, mapActions } from 'vuex';

export default {

    name: 'Basic',

    data: () => ({
        loading: false,
        query: null,
        posts: [],
        total: null,
        error: null,
        switchCO: true,

        // dialog single
        dialogSingle: false,
        dialogSinglePost: null,
        dialogSingleIndex: null,

        //trainer widget
        isUserProfileTrainer: false,

        //* PAGING
        page: 1,
        paginationLength: null,
        options: {
            itemsPerPage: 25,
        },
    }),

    components: {
        Post,
        Single,
        VoteWidget,
        TranslationsSelectWidget,
        PublisherWidget,
    },

    created() {
        // trainer widget : fetch full posts meta
        if (this.$rbac.get_user_role() == "admin" || this.$rbac.get_user_role().includes("trainer")) {
            this.isUserProfileTrainer = true;
        } 
    },

    watch: {
        searchStore: {
            deep: true,
            immediate: true,
            handler() {
                if (this.searchStore) {
                    this.query = this.searchStore.search;
                    this.page = this.searchStore.page;
                    this.switchCO = this.searchStore.only_co;
                    this.posts = this.searchStore.posts;
                    this.total =this.searchStore.total;
                    this.paginationLength = Math.ceil(this.total / this.options.itemsPerPage);
                }
            }
        }
    },

    methods: {

        ...mapActions(['updateBasicSearch','resetBasicSearch']),
        
        launchBasicSearch: async function() {
            console.log("Launch Basic search")
           
            let search = {
                search: this.query,
                page: this.page,
                only_co: false,
                
            }

            if (this.switchCO) {
                search.only_co = true;
            }
            
            this.loading = true;
            this.error = null;
            this.total = null,
            this.paginationLength= null,
            this.posts = [];
            
            let filter = (
                "?size=" + this.options.itemsPerPage + 
                "&page=" + (this.page - 1) +
                "&user_lang=" + this.$auth.retriveUserField("language_ui")
            );

            let payload = search;

            try {
                let res = await this.$api.post("/profile/searcher/basic" + filter, payload);
                this.posts = res.data
                this.total = parseInt(res.headers["x-paginated-items"]);
                this.paginationLength = Math.ceil(this.total / this.options.itemsPerPage);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error =  data.message;
            } finally {
                
                // update store
                search.posts = this.posts;
                search.total =this.total;
                this.updateBasicSearch(search);
                
                this.loading = false
            }
        },

        //* EVENTS

        // Vote
        eventVote(cl, index) {
            console.log(cl, index, this.posts[index].id);
            if (cl == 0) {
                this.dialogSingle = false;
                if (this.switchCO) {
                    this.posts.splice(index,1);
                } else {
                    this.posts[index].true_class = 0;
                }
            } else {
                this.posts[index].true_class = 1;
            }
        },

        //* FRAMER
        eventGoFramer(id) {
            console.log("eventGoFramer",id)
            searcher.setScroll(window.scrollY);
            this.$router.push({path: "/main/framer/" + id})
        },

        //* SINGLE
        eventOpenSingleDialog(index) {
            this.dialogSingle = true;
            this.dialogSinglePost = this.posts[index]
            this.dialogSingleIndex = index;
        },

        eventCloseSingleDialog(){
            this.dialogSingle = false;
        },


        //* NAVIGATION
        inputPage(pg) {
            this.page = pg;
            this.launchBasicSearch();
        },

        //* STYLING
        postColor(post) {
            let c = ""
            if (post.true_class == 1) {
                c = "accent"
            }
            return c
        }
    },

    computed: {
        ...mapGetters({
            searchStore: 'getBasicSearch',
        }),
    }

}
</script>

<style>

</style>