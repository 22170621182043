<template>
    <div>
        <v-tabs v-model="tab" centered  background-color="transparent" class="mt-0" key="subscriber-main-tabs">
            <v-tabs-slider :color="tabSliderColor"></v-tabs-slider>
            <v-tab @click="selectTab(0)" class="font-weight-bold accent--text">{{$i18n.t("news")}}</v-tab>
            <v-tab @click="selectTab(1)" class="font-weight-bold red--text text--accent-1">Publications</v-tab>
            <v-tab @click="selectTab(2)" class="font-weight-bold blue--text text--lighten-1"><v-icon small color="blue lighten-2" class="mr-2">mdi-bookmark</v-icon>{{$i18n.t("pool")}} {{poolTotal}}</v-tab>
        </v-tabs>
       
        <v-card flat outlined key="subscriber-main-tabs-items">
            <v-tabs-items v-model="tab" :active-class="backgroundThemeSwitcher" class="tab-items">
                <v-tab-item key="news">
                    <News 
                        @eventViewPostAddedInPool="eventViewPostAddedInPool"
                        @eventViewPostRemoveFromPool="eventViewPostRemoveFromPool" 
                        :propMainPostRemoveFromPool="propMainPostRemoveFromPool"
                    ></News>
                </v-tab-item>
                 <v-tab-item key="publications">
                    <Publications/>
                </v-tab-item>
                <v-tab-item key="pool">
                    <Pool 
                        @eventViewPostRemoveFromPool="eventViewPostRemoveFromPool" 
                        @eventPoolTotal="eventPoolTotal" 
                        :propMainPostAddedInPool="propMainPostAddedInPool"
                        :propMainPostRemoveFromPool="propMainPostRemoveFromPool"
                    ></Pool>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
        
    </div>
</template>

<script>
import subscriber from "@/common/subscriber";
import News from './SubscriberTabNews';

import Publications from '../../Deck/PublicationsTab.vue'
import Pool from './SubscriberTabPool';

export default {

    name: 'SubscriberMain',
    
    data: () => ({
        tab: 0,
        tabColors: ["accent", "red accent-1", "blue lighten-2"],
        propMainPostRemoveFromPool: null,
        propMainPostAddedInPool: null,
        poolTotal: null,
        foldersTotal: null,
    }),

    components: {
        News,
        Pool,
        Publications,
    },

    created() {
        // init tab
        this.tab = subscriber.retriveTab();
    },

    watch: {
        deep: true,
        immediate: true,
        handler() {
            subscriber.setTab(this.tab)
        }
    },

    methods: {

        selectTab(value) {
            subscriber.setTab(value)
        },
      
        // pick up when a post is added in pool in news.view
        // alert pool via propMainPostAddedInPool
        eventViewPostAddedInPool(pid) {
            this.propMainPostAddedInPool = pid;
        },

        // pick up when a post is removed from pool in pool.view
        // alert news via propMainPostRemoveFromPool
        eventViewPostRemoveFromPool(pid) {
            this.propMainPostRemoveFromPool = pid;
        },

        eventPoolTotal(t) {
            this.poolTotal = t;
        },

    },

    computed: {
        tabSliderColor() {
            return this.tabColors[this.tab];
        },

        backgroundThemeSwitcher() {
            if (this.$vuetify.theme.dark) {
                return "active-items-dark"
            } else {
                return "active-items-light"
            }
        }
    }
}
</script>

<style>
</style>