<template>
    <div>
        <v-sheet rounded outlined color="accent" key="card-form">
            <v-card flat key="form">
                
                <v-card-actions class="mx-3">
                    <span class="font-weight-bold text-body-1 py-1">CARD #{{ index + 1 }} - id:{{ card.id }} </span>
                    <v-spacer></v-spacer>
                    <v-btn x-small v-if="index != 0" @click="moveCardUp" color="accent"><v-icon small class="Dialog--text">mdi-arrow-up</v-icon></v-btn>
                    <v-btn x-small v-if="index < totalCards - 1" @click="moveCardDown" color="accent" class="ml-5 Dialog--text"><v-icon small>mdi-arrow-down</v-icon></v-btn>
                </v-card-actions>

                <v-divider></v-divider>

                <v-card-text>

                    <!-- Targets -->
                    <v-select
                        outlined dense
                        v-model="target"
                        :items="targets"
                        label="Target"
                        hide-details=""
                        class="mb-2"
                    ></v-select>
                    
                    <span v-if="target.includes('header')" >Only select one single class, the text will be bolded</span>

                    <!-- Selectors -->
                    <v-divider></v-divider>
                    <v-card-title class="justify-center">{{selectorsArray.length}} Selectors </v-card-title>
                    <v-card flat v-for="(selector,index) in selectorsArray" :key ="'selector' + index">
                        <v-card-actions>
                            <v-icon small @click="removeItemSelectorsArray(index)" color="accent">mdi-close-circle</v-icon>
                            <v-text-field
                                outlined dense
                                v-model="selectorsArray[index]"
                                :label='"Selector #" + index'
                                :rules="[noEndingComa,emptySpacesRule,noConsecutiveCommasRule]"
                                required
                                class="ml-3"
                                hide-details
                            ></v-text-field>
                        </v-card-actions>
                    </v-card>
                    <v-card-actions class="pt-0">
                        <v-spacer></v-spacer>
                        <v-btn x-small @click="addItemSelectorsArray()" class="Dialog--text" color="accent"><v-icon small>mdi-plus</v-icon></v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>


                    <!-- Exclude Strings -->
                    <v-divider></v-divider>
                    <v-card-title class="justify-center pb-0">{{exclusionStringsArray.length}} Excluded Strings </v-card-title>
                    <v-card-text class="text-center pt-0">- With meta, excluded string can filter out meta img url,</v-card-text>

                    <v-card flat v-for="(string,index) in exclusionStringsArray" :key ="'string' + index">
                        <v-card-actions>
                            <v-icon small @click="removeItemExclusionStringsArray(index)" color="accent">mdi-close-circle</v-icon>
                            <v-text-field
                                outlined dense 
                                v-model="exclusionStringsArray[index]"
                                :label='"Exclude String #" + index'
                                required
                                class="ml-3"
                                hide-details
                                :rules="[noEndingComa,noConsecutiveCommasRule]"
                            ></v-text-field>
                        </v-card-actions>
                    </v-card>
                    <v-card-actions class="pt-0">
                        <v-spacer></v-spacer>
                        <v-btn x-small @click="addItemExclusionStringsArray()" class="Dialog--text" color="accent"><v-icon small>mdi-plus</v-icon></v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>

                    <!-- Exclude Selectors -->
                    <v-divider></v-divider>
                    <v-card-title class="justify-center pb-0">{{exclusionSelectorsArray.length}} Excluded Selectors</v-card-title>
                    <v-card-text class="text-center py-0">value can be only an extract</v-card-text>
                    <v-card flat v-for="(exsel,index) in exclusionSelectorsArray" :key ="'exsel' + index">
                        <v-card-actions>
                            <v-icon small @click="removeItemExclusionSelectorsArray(index)" color="accent">mdi-close-circle</v-icon>
                            <v-text-field
                                outlined dense
                                v-model="exsel.key"
                                :label='"#" + index + " key"'
                                :rules="[noEndingComa,emptySpacesRule,noConsecutiveCommasRule]"
                                required
                                class="mt-0 mx-3"
                                hide-details
                            ></v-text-field>
                            <v-text-field
                                outlined dense
                                v-model="exsel.value"
                                :label='"#" + index + " value"'
                                :rules="[noEndingComa,emptySpacesRule,noConsecutiveCommasRule]"
                                required
                                class="mt-0 mx-3"
                                hide-details
                            ></v-text-field>
                        </v-card-actions>
                    </v-card>
                    
                    <v-card-actions class="pt-0">
                        <v-spacer></v-spacer>
                        <v-btn x-small @click="addItemExclusionSelectorsArray()" class="Dialog--text" color="accent"><v-icon small>mdi-plus</v-icon></v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>

                    <!-- insert img url -->
                    <v-divider></v-divider>
                    <v-card-text v-if="target.includes('img')">
                        Insert In Img Url [host...img_url]
                        <v-text-field
                            v-model="insertInImgUrl"
                            outlined dense
                            hide-details
                        ></v-text-field>
                    </v-card-text>
                    
                   
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn x-small @click="deleteCard" class="Dialog--text font-weight-bold" color="accent">delete</v-btn>
                        <v-btn x-small @click="saveCard" class="Dialog--text font-weight-bold" color="accent">save</v-btn>
                    </v-card-actions>
                   
                </v-card-text>
            </v-card>
        </v-sheet>
    </div>
</template>

<script>
export default {

    name: 'PostCard',

    props: ['card','index', 'totalCards'],

    watch: {
        
        card: {
            
            deep: true,
            immediate: true,
            handler() {
                this.selectorsArray = [];
                this.exclusionStringsArray = [];
                this.exclusionSelectorsArray = [];
                this.insertInImgUrl = this.card.insert_in_img_url;

                if (this.card) {
                    if (this.card.selectors) {
                        if (this.card.selectors.length > 0) {
                            this.selectorsArray = this.card.selectors.split('##');
                        }
                    }

                    if (this.card.exclusion_strings) {
                        if (this.card.exclusion_strings.length > 0) {
                            this.exclusionStringsArray = this.card.exclusion_strings.split('##');
                        }
                    }

                    if (this.card.exclusion_selectors) {
                        if (this.card.exclusion_selectors.length > 0) {
                            this.exclusionSelectorsArray = [];
                            let exsel = this.card.exclusion_selectors.split('##');
                            for (let exs of exsel) {
                                let ex = exs.split('&&');
                                this.exclusionSelectorsArray.push({key: ex[0], value: ex[1] })
                            }
                        }
                    }
                    
                    if (this.card.target) {
                        this.target = this.card.target
                    }
                }
            },
        },

        target: {
            deep: true,
            immediate: true,
            handler() {
                if (this.target == "meta-header-img" && this.selectorsArray.length == 0) {
                    this.selectorsArray.push("meta")
                } else if (this.target == "content-1" && this.selectorsArray.length == 0) {
                    this.selectorsArray.push("p,img,ul,h1,h2,h3,h4,h5,h6")
                }
            }
        },
    },

    data:() => ({

        insertInImgUrl: "",
        target: "",

        selectorsArray: [],
        exclusionStringsArray: [],
        exclusionSelectorsArray: [],
    
        targets: [
            "meta-header-img",
            "meta-header-1",
            "img-header-1",
            "header-1",
            "content-1",
            "content-2",
            "content-abonné",
            "content-public",
            "img-content-1",
            "img-content-2",
        ],
    }),

    methods: {

        addItemSelectorsArray() {
            this.selectorsArray.push("");
        },

        removeItemSelectorsArray(index) {
            this.selectorsArray.splice(index,1);
        },

        addItemExclusionStringsArray() {
            this.exclusionStringsArray.push("");
        },

        removeItemExclusionStringsArray(index) {
            this.exclusionStringsArray.splice(index,1);
            //this.card.exclusion_strings = this.exclusionStringsArray.join('##');
        },

        addItemExclusionSelectorsArray() {
            this.exclusionSelectorsArray.push({key: "class", value: ""});
        },

        removeItemExclusionSelectorsArray(index) {
            this.exclusionSelectorsArray.splice(index,1);
        },

        moveCardUp() {
            this.$emit('cardup', this.index);
        },

        moveCardDown() {
            this.$emit('carddown', this.index);
        },

        saveCard() {
            // trim any whitespaces before or after
            this.selectorsArray = this.trimWordsInArray(this.selectorsArray);
            this.exclusionStringsArray = this.trimWordsInArray(this.exclusionStringsArray);
            
            this.card.selectors = this.selectorsArray.join('##');
            this.card.exclusion_strings = this.exclusionStringsArray.join('##');

            let pcexsel = ""
            for (let exsel of this.exclusionSelectorsArray) {
                if (exsel.key != null && exsel.value != null) {
                    pcexsel += exsel.key.trim() + "&&" + exsel.value.trim() +  "##"
                }
            }
            this.card.exclusion_selectors = pcexsel.slice(0,-2);
            this.card.insert_in_img_url = this.insertInImgUrl;
            this.card.target = this.target
           
            this.$emit('update', this.card, this.index);
        },

        deleteCard() {
            this.$emit('delete', this.card,this.index);
        },

        trimWordsInArray(array) {
            let trimmedArray = [];
            for (let w of array){
                trimmedArray.push(w.trim());
            }
            return trimmedArray
        }
    },

    computed: {
        noEndingComa() {
            return (value) => {
                // Check if the value ends with a comma
                if (value.endsWith(',')) {
                    return 'Input should not end with a comma';
                }
                return true; // Validation passed
            };
        },

        emptySpacesRule() {
            return (value) => {
                if (/\s/g.test(value)) {
                    return "Empty spaces are not allowed.";
                } else {
                    return true;
                }
             };
        },

        noConsecutiveCommasRule() {
            return (value) => {
                const commaCount = (value.match(/,,/g) || []).length;
                if (commaCount > 0) {
                    return "Consecutive commas are not allowed.";
                } else {
                    return true;
                }
            };
        },

        noCommaAndSpaceRule() {
            return (value) => {
                const pattern = /,\s|,\s|,\s|\s,/;
                if (pattern.test(value)) {
                    return "Comma followed by an empty space or vice versa is not allowed.";
                } else {
                    return true;
                }
            };
        }
    }
}
</script>

<style>

</style>