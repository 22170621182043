<template>
    <div>
        <v-card tile flat color="grey darken-2" width="50" height="100%" class="">
            
            <!-- CL/POSTS -->
            <v-btn v-if="cluster_posts_count> 1" small text rounded class="mt-1" >{{cl_index}}.{{p_index}}</v-btn>
            
            
            <!-- drag button -->
            <v-btn x-small class="mt-3 ml-2" color="purple accent-2"><v-icon color="" small>mdi-drag</v-icon></v-btn>
           
            <!-- post in a new cluster-->
            <v-btn x-small @click="eventPostInNewCluster" class="mt-3 ml-2" color="purple accent-2"><v-icon small color="Dialog">mdi-moon-new</v-icon></v-btn>
            
            <!-- imgs/posts count -->
            <v-btn x-small text class="mt-2">{{ imgsPostCount }}/{{wordsCount}}</v-btn>
        </v-card>

    </div>
</template>

<script>
export default {

    name: 'ClusterPostWidget',

    props: ['post', 'cl_index', 'p_index', 'cluster_posts_count', 'see_out'],

    methods: {

        eventPostInNewCluster() {
            this.$emit("eventPostInNewCluster", this.cl_index, this.p_index, this.post);
        }
    },

    computed: {

        color() {
            if (this.post.is_out) {
                return "amber"
            } else {
                return"success"
            }
        },

        imgsPostCount() {
            let regex = /<img[^>]*src="([^"]*)"/g;
            let matches = this.post.content.match(regex);  // Find all matches for the regex
            let numberOfImages = matches ? matches.length : 0;  // Get the number of matches or 0 if none
            return numberOfImages
        },

        wordsCount() {
            let noHtml = this.post.content.replace(/<[^>]+>/g, "")
            return noHtml.split(" ").length;
        },
    }
}
</script>

<style>

</style>