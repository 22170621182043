<template>
    <div>
        
        <v-sheet rounded outlined color="lime" class="my-2 ml-1 mr-3" key="scheduler">
            <Scheduler
                :publication="publication"
                :thread="thread"
                :targets="targets"
                :imgs_in_thread="imgs_in_thread"
                :selected_images_urls="selected_images_urls"
                :cluster_post_ids="buildClusterPostIds"
                class=""
            />
        </v-sheet>
        <v-card flat>
            <v-card-text class="py-1">
                <!-- error msg -->
                <span v-if="publication_error && publication_error != 'empty'" class="error--text2">API Error: {{ publication_error }}</span>
                <!-- publication msg -->
                <span v-if="publication && publication.message.length > 0" class="error--text">Msg: {{ publication.message }}</span>
            </v-card-text>
        </v-card>

        <!-- THREAD -->
        <v-sheet outlined :color="thread_color" class="ml-1 mr-3" key="thread">
            <v-card flat tile>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- thread countries names -->
                    <div v-for="(n,index) of thread_country_names" :key="'country-' + index">
                        <v-chip v-if="n == thread_first_country" @click="updateCountryHeaderThread(n)" small  outlined class="mr-5" color="lime">
                            {{n}}
                        </v-chip>
                        <v-chip v-else @click="updateCountryHeaderThread(n)" small  class="mr-5" color="blue">
                            {{n}}
                        </v-chip>
                    </div>
                   
                    
                </v-card-actions>
            </v-card>
            <v-card tile flat v-for="(t, index) in thread" :key="'thread-' + index" class="" color="">
                
                <v-card-text>
                    <!-- thread text -->
                    <v-textarea outlined 
                        v-model="thread[index]" 
                        :label="threadLabel(index)" 
                        :error="threadNoHastagLength(index) > 280 ? true : false" 
                        auto-grow
                        class="my-0" 
                        :rules="[noDotSurroundedByText,noHashtagInWord,multipleHashtags,threeDigitsAfterDot]"
                        hide-details
                        :disabled="publication && publication.status == 'published'"
                        color="lime"
                    >
                        <template v-slot:prepend>
                            <v-card-subtitle class="px-1 pt-1 lime--text">#{{index + 1}}</v-card-subtitle>
                            <v-btn v-if="index > 0" small icon @click="deleteThread(index)"><v-icon color="error">mdi-close-circle-outline</v-icon></v-btn>
                            <v-btn v-if="index == 0 && threadCountryCount > 1" x-small class="mt-1 font-weight-bold" :color="threadCountryCount > 1? 'error':'success'">{{threadCountryCount}}</v-btn>
                        </template>
                    </v-textarea>
                </v-card-text>

                <!-- thread images -->
                <v-card-subtitle v-if="index < selected_images_urls.length && imgs_in_thread" class="pt-0">
                    <v-img contain height="200" :src="selected_images_urls[index]" class="pb-2"></v-img>
                </v-card-subtitle>
                
                <!-- header images collection -->
                <v-card-subtitle v-if="!imgs_in_thread && index == 0">
                    <span v-for="(i, index) in selected_images_urls" :key="'imgs-' + index">
                        {{index}}
                        <v-img contain height="120" :src="selected_images_urls[index]"></v-img>
                    </span>
                
                </v-card-subtitle>

            </v-card>

            <!-- ADD THREAD + CRUD -->
            <v-card flat tile class="pb-3" key="add-thread-crud">
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="addThread" class="Dialog--text" color="lime">add Thread</v-btn>
                    <v-spacer></v-spacer>

                    <v-btn small rounded v-if="!imgs_in_thread" class="orange--text" @click="imgs_in_thread = !imgs_in_thread">Imgs in Header</v-btn>
                    <v-btn small rounded v-if="imgs_in_thread" class="lime--text" @click="imgs_in_thread = !imgs_in_thread">Imgs in Thread</v-btn>
                    <v-spacer></v-spacer>
     
                    <v-btn small v-if="publication && publication.status == 'published'" color="blue">Published</v-btn>
                    <v-btn small  v-if="publication" @click="deletePublication" class="Dialog--text" color="error">Delete</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>

        </v-sheet>

        <v-sheet outlined :color="thread_color" class="ma-1 mr-3" key="targets">
            <v-card tile flat outlined class="" key="add-thread-targets">

                <!-- TARGETS -->
                <v-card-actions class="pt-0 pb-3">
                    <v-spacer></v-spacer>
                    <v-checkbox multiple v-model="targets" value="twitter" label="X" color="lime" hide-details></v-checkbox>
                    <v-checkbox multiple v-model="targets" value="bluesky" label="BS" color="blue" hide-details class="ml-2"></v-checkbox>
                    <v-checkbox multiple v-model="targets" value="instagram" label="IN" color="pink accent-2" hide-details class="ml-2"></v-checkbox>
                    <v-checkbox multiple v-model="targets" value="threads" label="TH" color="grey" hide-details class="ml-2"></v-checkbox>
                    <v-spacer></v-spacer>
                </v-card-actions>
                <v-card-subtitle v-if="publication" class="text-center py-0">pb id: {{publication.id}}</v-card-subtitle>
            </v-card>
        </v-sheet>

    </div>
</template>

<script>

import Scheduler from './Widgets/PublisherWidgetScheduler.vue'
export default {

    name: 'Thread',

    components: {
        Scheduler,
    },

    props: ['posts','selected_chat_text','selected_images_urls'],

    watch: {

        selected_chat_text: {
            deep: true,
            immediate: true,
            handler() {
                if (this.selected_chat_text && this.selected_chat_text.length > 0) {
                    this.buildThread(this.selected_chat_text);
                }
            }
        },

        posts: {
            deep: true,
            immediate: true,
            handler() {
                if (this.posts.length > 0) {
                   this.buildThreadCountry();
                   this.fetchPublication();
                }
            }
        },

        thread: {
            deep: true,
            immediate: true,
            handler() {
            }
        }
    },


    data: () => ({
        thread: [""],
        thread_first_country: "",
        thread_country_names: [],

        tweetMaxCharacters: 280,
        tweetShortenUrlLength: 25,
        imgs_in_thread: true,
        thread_color: "",

        targets: ["twitter","bluesky"],

        // publication
        publication_loading: false,
        publication_error: null,
        publication: null,
        publication_message: "",
    }),

    created() {
        this.fetchPublication();
    },

    methods: {


        buildThreadCountry() {
            let tags = [];
            this.thread_country_names =[];
            for (let p of this.posts) {
                for (let t of p.tags) {
                    if (t.channel == "country") {
                        // Check if the country already exists in the tags array
                        let existingTag = tags.find(c => c.ref === t.ref);
                        if (existingTag) {
                            // If found, increment the count
                            existingTag.count += 1;
                        } else {
                            // If not found, add it to the array with count = 1
                            tags.push({
                                ref: t.ref,
                                name: t.name,
                                count: 1,
                            });
                            
                        }
                    }
                }
            }
           
            if (tags.length > 1) {
                // Sort tags by count in descending order
                tags.sort((a, b) => b.count - a.count);
    
                // Return the top two tags
                this.thread_first_country = tags[0].name;
                for (let t of tags) {
                    this.thread_country_names.push(t.name);
                }
                 
            } else if (tags.length == 1) {
                this.thread_first_country = tags[0].name;
                this.thread_country_names.push(tags[0].name);
            }

        },

        //* Threads
        buildThread(chat) {
            this.thread = [];
            let threadIndex = 0;
            let summary = chat;
            // remove last dot
            if (summary.charAt(summary.length - 1) === ".") {
                summary = summary.slice(0, -1);
            }
            // modify `.'`=> `'.`
            summary = summary.replaceAll(".'","'.")
            summary = summary.replaceAll(`."`,`".`)

            summary = summary.replaceAll(`kilogramme`,`kg`)
            summary = summary.replaceAll(`kilograms`,`kgs`)
            summary = summary.replaceAll(`kilo`,`kg`)
            summary = summary.replaceAll(`kilos`,`kg`)

            // `Ndrangheta => `ndrangheta
            summary = summary.replaceAll(`Ndrangheta`,`ndrangheta`)

            summary = summary.replaceAll(`Turkey`,`Türkiye`)
            summary = summary.replaceAll(`U.S.`,`US`)
            summary = summary.replaceAll(`Turkish mafia`,`Turkish mafya`)

            summary = summary.replaceAll(`,"`,`",`)

            let summarySlice = summary.split(". ");

            for (let [index,c] of summarySlice.entries()) {
                if (c.length > 0) {
                    if (this.thread.length == 0) {
                        this.thread.push(this.$tools.capitalizeString(this.thread_first_country) + ": " + c + ". ");
                    } else if (this.thread[threadIndex].length + c.length < this.tweetMaxCharacters) {
                        this.thread[threadIndex] += c + ". "
                    } else {
                        threadIndex++;
                        if (index < summarySlice.length -1 ) {
                            this.thread.push(c + ". ")
                        } else {
                            this.thread.push(c +".")
                        }
                    }
                }
            }

            // trim each thread in order to prevent a thread to finish with a space after the final dot '. '
            let trimmedThread = []
            for (let th of this.thread) {
                trimmedThread.push(th.trim())
            }
            this.thread = trimmedThread 
        },

        updateCountryHeaderThread(country_name) {
            if (this.thread[0].length > 0) {
                this.$set(this.thread, 0, this.thread[0].replace(this.thread_first_country + ": ", country_name + ": ")); // Force reactivity by replacing the entire array
                this.thread_first_country = country_name;
            }
        },

        addThread() {
            console.log("adding thread");
            this.thread.push(">>>")
        },

        deleteThread(index) {
            this.thread.splice(index,1)
        },

        threadLabel(index) {
            let noHashtagThread = this.thread[index].replaceAll('#','')
            if (this.thread.length > 1) {
                return noHashtagThread.length + 4 + '/280 no # characters  - ' + (index + 1) + '/' + this.thread.length
            } else {
                return noHashtagThread.length + '/280 no # characters'
            }
        },

        threadNoHastagLength(index) {
            if (this.thread.length > 1) {
                return this.thread[index].replaceAll('#','').length + 4;
            } else {
                return this.thread[index].replaceAll('#','').length;
            }
        },
        
        //* PUBLICATION
        fetchPublication: async function () {

            let pids = []
            for (let p of this.posts) {
                pids.push(p.id)
            }

            // console.log("fetch publication for post:", pids.join(","))
            
            this.publication_loading = true;
            this.publication_error = null;

            let payload = {
                post_ids : pids.join(","),
            }

            //console.log(payload)
            
            try {
                let res = await this.$api.post("publisher/publication", payload);
                this.publication = res.data
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.publication_error = data.message;
            } finally {

                if (this.publication && this.publication.text.length > 0) {
                    
                    this.thread = this.publication.text.split("\n\n");
                    this.targets = this.publication.targets.split(",")
                    this.imgs_in_thread = !this.publication.imgs_only_header;
                    
                    // thread color
                    switch (this.publication.status) {
                        case "error":
                            this.thread_color = "error"
                            this.error = this.publication.message
                            break;
                        case "published":
                            this.thread_color = "blue"
                            break;
                        case "purged":
                            this.thread_color = "blue-grey"
                            break;
                        case "purge error":
                            this.thread_color = "purple accent-1"
                            this.error = this.publication.message
                            break;
                    }

                    // imgs
                    let publication_img_urls = this.publication.img_urls.split("#,#")
                    if (publication_img_urls.length > 0) {
                        this.$emit("eventPublicationImages", publication_img_urls)
                    }
                }

                this.publication_loading = false;
            }

        },

        deletePublication: async function() {
            console.log("deleting publication:", this.publication.id)

            if (!confirm("Remove Publication and associated targets  \"" + this.publication.id + "\"?")) {
                return;
            }
            this.publication_loading =true;
            this.publication_error = null;

            try {
                await this.$api.delete("/publisher/publication/" + this.publication.id);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.publication_error = data.message;
            } finally {
                this.publication_loading = false;
                this.$emit("eventClosePublisherDialog");
            }
        },
    },


    computed: {

        buildClusterPostIds() {
            let pids = []
            for (let p of this.posts) {
                pids.push(p.id)
            }
            return pids
        },

        // rules
        noSpaceAfterHashtag() {
            return (value) => {
                // Define a regular expression to match # followed by one or more spaces and then a letter
                const regex = /#\s+\S/;

                // Test the input string against the regular expression
                if (regex.test(value)) {
                    return "Empty spaces after hashtag";
                } else {
                    return true;
                }
            }
        },

        noDotSurroundedByText() {
            return (value) => {
                // Define a regular expression to match text followed by a dot and then more text, without spaces around the dot
                //const regex = /\b\w+\.\w+\b/;
                const regex = /\b[a-zA-Z]+\.[a-zA-Z]+\b/;


                // Test the input string against the regular expression
                if (regex.test(value)) {
                    return "Dot surrounded by text without spaces detected";
                } else {
                    return true;
                }
            }
        },

        threadCountryCount() {
            
            if (!this.thread_first_country || !this.thread[0]) {
                return 0;
            }

            // Create a case-insensitive regular expression to match the word
            const regex = new RegExp(`\\b${this.thread_first_country}\\b`, 'gi');

            // Match the text against the regular expression
            const matches = this.thread[0].match(regex);

            // Return the number of matches, or 0 if no matches were found
            return matches ? matches.length : 0;
            
        },

        noHashtagInWord() {
            return (value) => {
                // Define a regular expression to match hashtags that are not at the beginning of a word or are inside a word
               const regex = /\b\w+#\w+\b/;

                // Test the input string against the regular expression
                if (regex.test(value)) {
                    return "Hashtag not at the beginning of a word or inside a word detected";
                } else {
                    return true;
                }
            }
        },

        multipleHashtags() {
            return (value) => {
                // Define a regular expression to match hashtags that are not at the beginning of a word or are inside a word
               const regex = /##/g;

                // Test the input string against the regular expression
                if (regex.test(value)) {
                    return "Multiple Hashtag";
                } else {
                    return true;
                }
            }
        },

        threeDigitsAfterDot() {
            return (value) => {
                // Define a regular expression to match numbers with three digits following a dot
                const regex = /\b\d+\.\d{3,4}\b/g;

                // Test the input string against the regular expression
                if (regex.test(value)) {
                    return "Number with three digits after dot";
                } else {
                    return true;
                }
            }
        }



    },
}
</script>

<style>

</style>