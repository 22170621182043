<template>
    <div>

        <v-card flat outlined class="d-flex ma-1" >
            
            <!-- Add Button -->
            <v-card flat color="" class="d-flex flex-column ma-1">
                <v-btn v-if="show" 
                    @click="addImage" 
                    height="190" 
                    class="mb-1" 
                    color="blue"
                >
                    <v-icon large>mdi-plus-circle</v-icon>
                </v-btn>
                <v-btn @click="show = !show" height="30"  width="68" class="" color="blue">
                    <v-icon v-if="show">mdi-arrow-up</v-icon>
                    <v-icon v-else>mdi-arrow-down</v-icon>
                </v-btn>
            </v-card>

            <!-- Images -->
            <v-card v-if="show" flat class="d-flex overflow-y-auto pt-1 pb-3" key="frame-images-grid" color="">
                
                    <v-card flat v-for="(iu,index) in buildImagesUrls" :key="'img-' + index" :ripple="false" class="mr-1" color="grey darken-3" height="223" width="300">
                        
                        <v-card-actions class="py-1">
                            <v-btn small  text>{{index + 1}}/{{buildImagesUrls.length}}</v-btn>
                            <v-spacer></v-spacer>
                            <v-icon class="mr-2" @click="copyImageUrl(iu)">mdi-link</v-icon>
                            <v-spacer></v-spacer>
                            <v-icon class="mr-2" @click="toggleSnackbar(index)">mdi-eye</v-icon>
                            <v-spacer></v-spacer>
                            <v-icon v-if="added_urls.includes(buildImagesUrls[index])" class="mr-2" @click="deleteImage(index)" :color="sheetColor(index)">mdi-close-circle</v-icon>
                        </v-card-actions>
                        
                        <v-divider class="" :color="sheetColor(index)"></v-divider>
                        <v-sheet  :color="sheetColor(index)" rounded outlined>
                            <v-card flat @click="switchSelectImage(index)">
                                <v-img
                                    :src="iu"
                                    class=""
                                    min-width="250"
                                    
                                    height="160"
                                    contain
                                >
                                    <!-- Overlay for showing index in selected_imgs -->
                                    <v-overlay v-if="selected_imgs.includes(buildImagesUrls[index])" 
                                        absolute
                                        opacity="0"
                                        class="d-flex align-end justify-end pa-1"
                                    >
                                        <div class="text-h5 blue--text">
                                            <v-btn x-small fab color="blue" class="font-weight-bold">
                                                {{ selected_imgs.indexOf(buildImagesUrls[index]) + 1 }}
                                            </v-btn>
                                        </div>
                                    </v-overlay>
                                </v-img>
                            </v-card>
                        </v-sheet>
                    </v-card>
               
            </v-card>


            <v-card tile flat v-else ><v-btn class="lime--text ">CL {{cl_index}} · imgs selected {{selected_imgs.length}}/{{cl_img_urls.length}}</v-btn></v-card>
        </v-card>

        <!-- url snackbar -->
        <v-snackbar 
            v-model="snackbar"
            centered
            outlined
            timeout="3000"
        >
            {{snackbar_url}}
        </v-snackbar>

        <!-- snack bar: url image copied -->
        <v-snackbar centered v-model="snackbar_copied" timeout="800" color="blue">
            <span class="font-weight-bold">Url Copied</span>
            <template v-slot:action="{ attrs }">
                <v-btn
                    small
                    class="D"
                    text
                    v-bind="attrs"
                    @click="snackbar_copied = false"
                >
                    {{ $i18n.t("close") }}
                </v-btn>
            </template>
        </v-snackbar>

        <!-- add image dialog -->
        <v-dialog v-model="dialog" max-width="500px">
            <v-sheet rounded outlined color="blue">
                <v-card outlined flat class="ma-1 pa-1">
                    <v-card-text>
                        <v-text-field v-model="add_image_url"
                            dense
                            outlined
                            label="Add Image Url"
                            hide-details
                            class="pt-3"
                            color="blue"
                            :rules="[urlExistsRule]"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn v-if="add_image_url && add_image_url.length > 5 && urlExistsRule"
                            color="blue"
                            @click="addDialog"
                            class="Dialog--text"
                        >
                            Add
                        </v-btn>
                        <v-btn v-if="!urlExistsRule || !add_image_url" @click="closeDialog" class="Dialog--text" color="blue">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-sheet>

        </v-dialog>
    </div>
</template>

<script>
export default {

    name: 'ImagesCatalogue',

    props: ['cl_img_urls','cl_index'],

    data: () => ({
        added_urls: [],
        selected_imgs: [],
        add_image_url: null,

        base_size: 240,

        snackbar: false,
        snackbar_url: null,
        dialog: false,
        snackbar_copied: false,

        show: true,
    }),

    created() {
        // init: store first image
        if (this.cl_img_urls.length > 0) {
            this.selected_imgs.push(this.cl_img_urls[0])
            this.$emit("eventCatalogueSelectedImages", this.selected_imgs)
        }
    },

    methods: {

        switchSelectImage(index) {
            let u_index = this.selected_imgs.indexOf(this.buildImagesUrls[index]);
            if (u_index == -1) {
                this.selected_imgs.push(this.buildImagesUrls[index])
            } else {
                this.selected_imgs.splice(u_index,1);
            }
            this.$emit("eventCatalogueSelectedImages", this.selected_imgs)
        },

        sheetColor(index) {
            if (this.selected_imgs.includes(this.buildImagesUrls[index])) {
                return "blue"
            }
            return ""
        },

        toggleSnackbar(index) {
            this.snackbar = true;
            this.snackbar_url = this.buildImagesUrls[index];
        },

        addImage() {
            this.dialog = true;
        },

        deleteImage(index) {
            let added_index = this.added_urls.indexOf(this.buildImagesUrls[index]);
            if (added_index !== -1) {
                // Remove the value using splice
                this.added_urls.splice(added_index, 1);
            }
            let sel_index = this.selected_imgs.indexOf(this.buildImagesUrls[index]);
            if (sel_index !== -1) {
                // Remove the value using splice
                this.selected_imgs.splice(sel_index, 1);
            }
        },

        addDialog() {
            // image url pushed at the beginning
            this.added_urls.unshift(this.add_image_url.trim());
            this.dialog = false;
            this.add_image_url = null;
        },

        closeDialog() {
            this.dialog = false;
            this.add_image_url = null;
        },

        // copy img url
        copyImageUrl(text) {
            if (text.length > 0) {
                if (navigator.clipboard) {
                    navigator.clipboard.writeText(text)
                        .then(() => {
                            console.log('Url copied to clipboard');
                            this.snackbar_copied = true; 
                        })
                        .catch(err => {
                            console.error('Failed to copy text: ', err);
                        });
                } else {
                    console.warn('Clipboard API is not supported in this browser');
                }
            }
        },
        
    },

    computed: {
       
        urlExistsRule() {
            return value => {
                if (this.buildImagesUrls.includes(value)) {
                    return false;
                }
                return true; // Valid if the URL doesn't exist
            };
        },

        buildImagesUrls() {
            return [...this.added_urls, ...this.cl_img_urls]
        }
    }
}
</script>

<style>

</style>