<template>
    <div>
        <v-card v-if="post" flat color="transparent" class="d-flex">
          
            <v-avatar v-if="post.img_url.length > 0"
                size="150"
                rounded
                :href="'/#/main/single/' + post.id" 
                style="cursor: pointer"
                class="ma-0 my-2"
                @click="eventGoSingle"
            >
                <v-img :src="post.img_url"></v-img>
            </v-avatar>
          
            <v-card flat color="transparent">
                <v-card-title 
                    @click="eventGoSingle" 
                    style="cursor: pointer" 
                    class="wordbreak text-body-1 font-weight-bold ml-2 pt-2 pb-0"
                  
                >
                    {{getTitle}}
                </v-card-title>
              
                        
                <v-card-actions class="py-0">
                    <v-card-subtitle class="py-1">
                        <a :href="post.url">
                            <v-chip outlined small @click="goArticle" :color="colorView" class="font-weight-bold pl-1 pr-2">
                                <country-flag :country='post.country' size='small' :rounded="true" class=""/><span class="pTagText--text">{{post.source_name}}</span>
                            </v-chip>
                        </a>
                        
                        · {{formatPostDate}} | ES {{formatCreatedAt}} · {{post.id}} · {{ wordsCount }} words
                    </v-card-subtitle>

                    <!-- Translation Widget-->
                    <TranslationWidget
                        :post="post"
                        :tab="tab"
                        stream="post"
                        class="ml-2"
                        @eventTranslation="eventTranslation"
                    ></TranslationWidget>

                </v-card-actions>

                <!-- Tags Widget-->
                <v-card-actions class="py-0">
                    <TagsWidget 
                        :tags="post.tags" 
                        :pid="post.id" 
                        :language="post.language" 
                        :tab="tab"
                        :are_tags_confirmed="post.are_tags_confirmed"
                        @eventGoTags="eventGoTags"
                        stream="post" 
                        class="ml-4"
                    ></TagsWidget>
    
                </v-card-actions>
                
                <!-- Loot Widget -->
                <v-card-actions  class="py-0">
                    <LootWidget
                        :loot="post.loot"
                        :pid="post.id"
                        :language="post.language" 
                        @eventGoLoot="eventGoLoot"
                        stream="post" 
                        class="ml-4"
                    ></LootWidget>
                </v-card-actions>
                
                <!-- Description -->
                <v-card-text class="wordbreak pTagText--text py-1 ml-2 text-body-2">{{excerptDescription(description)}}</v-card-text>
            </v-card>
        </v-card>
    </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from 'vuex';
import TagsWidget from '../../Tags/TagsWidget.vue'
import LootWidget from '../../Loot/LootWidget.vue'
import TranslationWidget from '../../Translations/TranslationWidget.vue'

export default {

    name: 'Post',

    props:['post','index','tab','colorView'],

    data: () => ({
        title: null,
        description: null,
        excerptWordCount: 45,
    }),

    components: {
        TagsWidget,
        TranslationWidget,
        LootWidget,
    },

    created() {
        
        this.title = this.post.title;
        this.description = this.post.description;

        if (this.trans_settings[this.post.language] && this.post) {
            
            if (this.trans_settings[this.post.language].available.length > 0) {
                let trans_active = false;
                for (let ol of this.trans_settings[this.post.language].available) {
                    if (ol.trans_active) {
                        this.setTranslations(ol.iso);
                        trans_active = true;
                    } 
                }
                if (!trans_active) { 
                    this.original() 
                }
            }
        }
    },

    watch: {
      
        trans_settings: {
            deep: true,
            immediate: false,
            handler() {
                if (this.trans_settings && this.trans_settings[this.post.language].available.length > 0) {
                    let trans_active = false;
                    for (let ol of this.trans_settings[this.post.language].available) {
                        if (ol.trans_active) {
                            this.setTranslations(ol.iso);
                            trans_active = true;
                        } 
                    }
                    if (!trans_active) { this.original() }
                }
            },
        },
        
    },

    methods: {

        goArticle(event) {
            window.open(this.post.url, '_blank');
            event.preventDefault();
        },


        setTranslations(language) {
            if (this.post && this.post.translations) {
                for (let trans of this.post.translations) {
                    if (trans.output_language == language) {
                        if (trans.title.length > 0) {
                            this.title = "[" + trans.title.trim() + "]";
                        }
                        if (trans.description.length > 0) {
                            this.description = "[" + trans.description.trim() + "]";
                        }
                    }
                }
            }
        },

        // UI shortener description
        excerptDescription(d) {
            let arr = d.split(/\s+/);
            if (arr.length > this.excerptWordCount) {
                return arr.slice(0,this.excerptWordCount).join(" ") + "..."
            } else {
                return d
            }
        },

        eventGoSingle() {
            this.$emit("eventGoSingle", this.index);
        },

        eventGoTags() {
            this.$emit("eventGoTags", this.index);
        },

        eventGoLoot() {
            this.$emit("eventGoLoot", this.index);
        },

        eventGoCorpus() {
            this.$emit("eventGoCorpus", this.index);
        },

        eventGoMiner() {
            this.$emit("eventGoMiner", this.index);
        },

        original() {
            this.title = this.post.title;
            this.description = this.post.description;
        },

        eventTranslation(...[,,description,title]) {
            if (description.length > 1) {
                this.description = description;
            }
            if (title.length > 1) {
                this.title = "[" + title  + "]";
            }
            // needed for updating splinter post
            this.$emit("eventTranslation", description, title)
        },
    },

    computed: {

        ...mapGetters({
            trans_settings: 'getTransSettings',
        }),

        getTitle() {
            return this.title
        },

        formatCreatedAt() {
            return moment(this.post.created_at).format("DD/MM/YY@HH:MM")
        },

        formatPostDate() {
            return moment(this.post.date).format("DD/MM/YY")
        },

        sinceDate() {
            return moment(this.post.created_at).locale(this.$auth.retriveUserField("lang")).fromNow();
        },

        wordsCount() {
            let noHtml = this.post.content.replace(/<[^>]+>/g, "")
            return noHtml.split(" ").length;
        },

    }
}
</script>

<style>
    /* .pTagText--text defines like Dialog--text */
    
    .wordbreak {
        word-break: normal;
    }
</style>