<template>
    <div>
        <!-- add -->
        <v-chip x-small @click="addLoot" color="amber" class="mr-1">
            <v-icon small class="Dialog--text">mdi-plus</v-icon>
        </v-chip>

        <span v-for="(l, index) in buildLoot" :key="index" 
            class="mr-2"
        >
            <v-btn 
                x-small
                outlined
                class="mr-1"
                color="amber"
                :ripple="false"
                @click="changeLoot(l, index)">
                    {{valueFormat(l)}}  
                <span class="pink--text ml-1">{{l.name}}</span> <span v-if="l.count > 1">({{l.count}})</span>
            </v-btn>
            <v-icon @click="removeLootValueForClusterPosts(l)" small class="ml-0" color="amber">mdi-close-circle</v-icon>
        </span>

        <!-- error msg -->
        <span v-if="error" class="ml-5 error--text">API Error: {{ error }}</span>

        <!-- DIALOG ADD TAG -->
        <v-dialog v-model="dialog" width="400">
            <v-sheet rounded outlined color="amber">
                <v-card flat>
                    
                    <!-- title -->
                    <v-card-title class="amber font-weight-bold Dialog--text">
                        CLUSTER #{{cl_index}} LOOT 
                        <span v-if="!add_not_change">: {{selected_loot.name}}</span>
                    </v-card-title>
                    
                    <!-- loading -->
                    <v-progress-linear
                        indeterminate
                        color="amber"
                        :active="loading">
                    </v-progress-linear>
                    
                    <v-card-text class="">
                        
                        <!-- loot value -->
                        <v-text-field
                            dense
                            outlined
                            v-model.number="selected_loot_value"
                            label="value"
                            color="amber"
                            :suffix='selected_loot_type === "weight"? "Kg" : "Objects"'
                            required
                            hide-details
                        ></v-text-field>

                        <!-- type: weight/objects -->
                        <v-radio-group v-if="add_not_change" row v-model="selected_loot_type">
                            <v-radio small label="weight" color="amber" value="weight"></v-radio>
                            <v-radio small label="objects" color="amber" value="object"></v-radio>
                        </v-radio-group>

                        <!-- loot tags list -->
                        <v-autocomplete v-if="search_tag_by_name"
                            outlined
                            dense
                            v-model="selected_tag"
                            :items="buildMarketLootList"
                            item-text="name"
                            color="amber"
                            clearable
                            required
                            class="mt-2"
                            return-object
                            hide-details
                        ></v-autocomplete>
                        <v-autocomplete v-if="!search_tag_by_name"
                            outlined
                            dense
                            v-model="selected_tag"
                            :items="buildMarketLootList"
                            color="amber"
                            clearable
                            required
                            class="mt-2"
                            return-object
                            hide-details
                        ></v-autocomplete>

                    </v-card-text>


                    <v-divider></v-divider>
                    <v-card-actions>

                        <v-spacer></v-spacer>
                        <v-btn v-if="add_not_change"
                            color="amber"
                            class="Dialog--text"
                            @click="addLootToClusterPosts"
                        >
                            Add
                        </v-btn>
                        <v-btn v-else
                            color="amber"
                            class="Dialog--text"
                            @click="updateLootValueForClusterPosts"
                        >
                            Save
                        </v-btn>

                        <v-spacer></v-spacer>
                        <v-btn
                            color="amber"
                            text
                            @click="dialog = false"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-card-actions>
                    
                </v-card>
            </v-sheet>
        </v-dialog>
       
          
    </div>
</template>

<script>
export default {

    name: 'LootWidget',

    props: ['cluster','cl_index','tags_list'],

    data: () => ({
        loading: false,
        error : null,
        selected_loot: null,
        selected_tag: null,
        selected_loot_index: -1,
        selected_loot_value: 0,
        selected_loot_type: "weight",
        dialog: false,
        add_not_change: true,
        search_tag_by_name: true,
    }),

    methods: {

        //* Add Tag to post
        addLoot() {
            this.dialog = true;
            this.selected_loot= null;
            this.selected_loot_value = 0;
            this.add_not_change = true;
        },

        changeLoot(l, index) {
            this.dialog = true;
            this.selected_loot_index = index;
            this.selected_loot = l;
            this.selected_tag = this.tags_list.find(tag => tag.ref === l.ref);
            this.selected_loot_value = l.value;
            this.selected_loot_type = l.category,
            this.add_not_change = false;
        },

        addLootToClusterPosts: async function() {
            
            console.log("adding new loot=", this.selected_loot.ref);
            this.loading = true;
            this.error = null;

            // it needs to be sequential since all posts doesnt have the same language => index
            // so the update by query has to be sequential
            for (let p of this.cluster.posts) {

                let payload = {
                    post_id: p.id,
                    value: this.selected_loot_value,
                    ref: this.selected_tag.ref,
                    language: p.language,
                    category: this.selected_loot_type,
                }

                //console.log(payload)

                // alerts
                if (payload.value == 0) {
                    window.alert("loot empty value !");
                    this.dialog = false
                    this.loading = false
                    return 
                }

                if (payload.ref.length == 0) {
                    window.alert("loot ref empty !");
                    this.dialog = false
                    this.loading = false
                    return 
                }

                if (this.buildClusterLootRefList().includes(payload.ref)) {
                    window.alert("loot with tag ref=" + payload.ref + " already in! Use Change");
                    this.dialog = false
                    this.loading = false
                    return 
                }

                try {
                    await this.$api.post("/looter/loot/widget/add", payload);
                } catch (e) {
                    let data = (e.response || {}).data || "unknown error";
                    this.error += data.message + "/";
                } finally {
                    // add locally if success
                    p.loot.push(this.selected_loot)
                }


            }
            this.loading = false;
            this.dialog = false;
        },


        updateLootValueForClusterPosts: async function() {
            console.log("updating loot for cluster posts=", this.selected_loot.ref);
            this.loading = true;
            this.error = null;

            // it needs to be sequential since all posts doesnt have the same language => index
            // so the update by query has to be sequential
            for (let p of this.cluster.posts) {

                let payload = {
                    post_id: p.id,
                    loot: [],
                    error: 0,
                    language: p.language,
                }

                //console.log(payload)

                // alerts
                if (payload.value == this.getValueForLoot) {
                    window.alert("No change. Loot same value !");
                    this.dialog = false
                    this.loading = false
                    return 
                }

                // build updated post loot
                let loot_payload = [];
                let updated_post_loot = [];
                for (let [index,l] of p.loot.entries()) {

                    if (index == this.selected_loot_index) {
                        l.value = this.selected_loot_value;
                        l.tag_ref = this.selected_loot.ref;
                        l.tag_name = this.selected_tag.name;
                    }

                    updated_post_loot.push(l);
                    
                    loot_payload.push(JSON.stringify(
                        {
                            value: l.value,
                            category: l.category,
                            tag_ref: l.tag_ref,
                        }
                    ))
                }
                payload.loot = loot_payload;

                // alerts
                if (payload.loot.length == 0) {
                    window.alert("payload loot empty !");
                    this.dialog = false
                    this.loading = false
                    return 
                }

                //console.log(payload.loot);
                //console.log(updated_post_loot);

                try {
                    await this.$api.post("/looter/loot/widget/update", payload);
                } catch (e) {
                    let data = (e.response || {}).data || "unknown error";
                    this.error += data.message + "/";
                } finally {
                    // add locally if success
                    p.loot = updated_post_loot;
                  
                }
            }

            this.dialog = false;
            this.loading =false;
        },

        removeLootValueForClusterPosts: async function(loot) {
            console.log("removing tag from posts in cluster", this.cl_index, "with=", loot.ref);
            this.error = null;
            
            // it needs to be sequential since all posts doesnt have the same language => index
            // so the update by query has to be sequential
            for (let p of this.cluster.posts) {

                let payload = {
                    post_id: p.id,
                    value: loot.value,
                    ref: loot.ref,
                    language: p.language,
                    category: loot.category,
                }

                console.log(payload)

                if (payload.ref.length == 0) {
                    window.alert("loot ref empty !");
                    return 
                }

                try {
                    await this.$api.post("/looter/loot/widget/remove", payload);
                } catch (e) {
                    let data = (e.response || {}).data || "unknown error";
                    this.error += data.message + "/";
                }
            }

            this.dialog = false;
            this.$emit("eventRemoveLootFromCluster", this.cl_index, loot.ref)
        },


        // allows to detect duplicates
        buildClusterLootRefList() {
            let list = [];
            if (this.cluster.posts && this.cluster.posts.length > 0) {
                for (let p of this.cluster.posts) {
                    for (let l of p.loot) {
                        list.push(l.tag_ref);
                    }

                }
             }
            return list
        },

        valueFormat(loot) {
        
            if (loot.category == "weight") {
                // 42780 => 42.78K
                if (parseInt(loot.value, 10) >= 1000) {

                    var value = parseInt(loot.value, 10);
                    if (value >= 1000) {
                        var result = value / 1000;
                        if (Number.isInteger(result)) {
                            return result + " tons";
                        } else {
                            return result.toFixed(2) + " tons";
                        }
                    }

                } else if (parseInt(loot.value, 10) >= 1) {
                    return Math.round(100 * loot.value)/100 + " kgs";
                } else {
                    // neede floating point in js handling
                    let val = Math.round(loot.value * 100000) / 100
                    return val + " gr";
                }
            } else if (loot.category == "cash" || loot.category == "worth" || loot.category == "object") {

                if (loot.value > 1000000000) {
                    return Math.round(loot.value / 1000000000 * 10) / 10  +  "B"
                }

                if (loot.value > 1000000 && loot.value < 1000000000 ) {
                    return Math.round(loot.value / 1000000 * 10) / 10  +  "M"
                }

                if (loot.value > 1000 && loot.category != "object") {
                    return Math.round(loot.value / 1000 * 10) / 10  +  "K"
                }
            }

            return loot.value
        },
    },

    computed: {

        buildLoot() {
            let loot = []
            if (this.cluster.posts) {
                for (let p of this.cluster.posts) {
                    if (p.loot) {
                        for (let l of p.loot) {
                            
                            // Check if the loot already exists in the loot array by matching both tag_ref and value
                            let existingLoot = loot.find(c => c.ref === l.tag_ref && c.value === l.value);
                            
                            if (existingLoot) {
                                // If found, increment the count
                                existingLoot.count += 1;
                            } else {
                                // If not found, add it to the array with count = 1
                                loot.push({
                                    ref: l.tag_ref,
                                    name: l.tag_name,
                                    value: l.value,
                                    count: 1,
                                    category: l.category,
                                });
                            }
                            
                            
                        }
                    }
                }
            }

            // Sort loot by count in descending order
            loot.sort((a, b) => b.count - a.count);
            
            return loot
        },

        // only relevant tags for loot => path includes "market"
        buildMarketLootList() {
            let loot_tags =[];
            for (let t of this.tags_list) {
                if (t.path.includes("market")) {
                    loot_tags.push(t)
                }
            }
            return loot_tags
        },


        // returns teh value for a single loot ref
        getValueForLoot() {
            let v = -1;
            if (this.cluster.posts && this.cluster.posts.length > 0) {
                for (let p of this.cluster.posts) {
                    for (let l of p.loot) {
                       if (l.tag_ref == this.selected_loot.ref) {
                            v = l.value
                       }
                    }
                }
             }
            return v
        },

    },

}
</script>

<style>

</style>