<template>
    <div>
        <v-sheet outlined rounded color="purple" key="tag-title" class="mb-2">
            <v-card v-if="tag" flat outlined class="">
                <v-card-title v-if="isNew" class="justify-center purple--text text-h4 font-weight-bold">
                    New Tag  [channel: {{channel}}]
                </v-card-title>
                <v-card-title v-else class="justify-center purple--text text-h4 font-weight-bold">
                    TAG: "{{ tag.en }}"
                </v-card-title>
            </v-card>
        </v-sheet>

        <v-card v-if="tag" key="tag-form">

            <!-- error msg -->
            <span v-if="error" class="ml-5 error--text">API Error: {{ error }}</span>

            <!-- loading -->
            <v-progress-linear
                indeterminate
                color="purple lighten-2"
                :active="loading">
            </v-progress-linear>

            <v-container fluid key="iso-lang-list">
                <v-row>
                    <v-col cols="7">
                        <v-card-text>
                            
                            <!-- tag parent -->
                            <v-select v-if="tagsList.length > 0"
                                outlined
                                dense
                                :items="tagsList"
                                v-model="parentTag"
                                label="Parent Tags List"
                            >   
                                <template v-slot:item="{ item }">
                                   <span :class="tagsFamilyColorText(item)">{{tagsFamilyName(item)}}</span>
                                </template>
                                <template v-slot:selection="{ item }">
                                    <span>{{ item.name }}</span>
                                </template>
                            </v-select>

                            <v-autocomplete  v-if="tagsList.length > 0"
                                outlined
                                dense
                                :items="tagsStore"
                                item-text="ref"
                                label="Type in ref"
                                v-model="parentTag"
                                return-object
                            ></v-autocomplete>

                            <v-card-subtitle v-if="tagsList.length == 0" class="mb-2">
                                <v-icon color="warning">mdi-alert</v-icon> to modify tag parent or path: go to to <span @click="goTags"><a>Tags</a></span> (in order to fetch the full list of tags)
                            </v-card-subtitle>

                            <!-- tag parent_ref -->
                            <v-text-field
                                rounded
                                outlined dense
                                v-model="tag.parent"
                                label="Parent Ref (no parent ref for level 0 tag)"
                            ></v-text-field>

                            <!-- tag ref -->
                            <v-card-text v-if="channel == 'crimorg'" class="pt-0 warning--text font-weight-bold">Syndicates children tags: select country iso3 as tag ref [format: s_iso3]</v-card-text>
                            <v-card-text v-if="channel == 'law'" class="py-0 warning--text font-weight-bold">Law agencies children tags: select country iso3 as tag ref [format: l_iso3]</v-card-text>
                            <v-card-text v-if="channel == 'law'" class="py-2 warning--text font-weight-bold">format agencies => 'iso3_' + (fra_customs)</v-card-text>

                            <!-- ref -->
                            <v-text-field
                                rounded
                                outlined dense
                                v-model="tag.ref"
                                label="Ref"
                            ></v-text-field>

                            <!-- continent for country -->
                            <v-text-field v-if="tag.parent == 'country'"
                                rounded
                                outlined dense
                                v-model="tag.continent"
                                label="Continent"
                            ></v-text-field>

                            <!-- path -->
                            <v-text-field
                                rounded
                                outlined dense
                                v-model="tag.path"
                                label="Path"
                                disabled
                            ></v-text-field>

                            <!-- level -->
                            <v-text-field
                                rounded
                                outlined dense
                                v-model.number="tag.level"
                                label="Level (always -1 / length path)"
                                disabled
                                width="40%"
                                hide-details
                            ></v-text-field>
                          
                            <v-card v-if="(channel == 'crimorg' || channel == 'law') && (tag.parent == 'crimorg' || tag.parent == 'law')" outlined class="mt-2">
                                
                                <v-card-actions class="py-1">
                                    <v-autocomplete
                                        outlined
                                        dense
                                        :items="countriesStore"
                                        item-text="name"
                                        item-value="iso3"
                                        label="search iso3 for a country"
                                        v-model="iso3"
                                        hide-details
                                    ></v-autocomplete>
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        rounded
                                        outlined dense
                                        v-model="iso3"
                                        label="Iso3"
                                        hide-details
                                    ></v-text-field>
                                </v-card-actions>
                            </v-card>

                        </v-card-text>

                        <v-card-actions>
                          
                            <v-spacer></v-spacer>
                            <!-- add_tag_parent -->
                            <v-switch v-model="tag.add_tag_parent" label="Add tag Parent ?" color="success"></v-switch>

                            <!-- only name -->
                            <v-spacer></v-spacer>
                            <v-checkbox v-model="onlyName" label="Only Name ?" color="purple">Only Name</v-checkbox>

                            <!-- has_label -->
                            <v-spacer></v-spacer>
                            <v-switch v-model="tag.has_label" label="Has Label ?" color="success"></v-switch>
                            
                            <v-spacer></v-spacer>
                        </v-card-actions>
                            
                        <v-card-text class="py-0">
                            <!-- tokens length check -->
                            <v-card-text v-if="tokens.length == 29 && !onlyName" class="success--text">Tokens length = {{tokens.length}}</v-card-text>
                            <v-card-text v-if="tokens.length != 29 && !onlyName" class="error--text">Tokens length = {{tokens.length}}</v-card-text>
                            
                            <!-- chat GPT tokens string -->
                            <v-textarea
                                v-if="!onlyName"
                                label="Chat GPT iso: token"
                                v-model="chatGPTstring"
                                auto-grow
                                outlined
                            ></v-textarea>

                            <!-- only name -->
                            <v-text-field
                                v-if="onlyName"
                                rounded
                                outlined dense
                                label="Name"
                                v-model="onlyNameTerm"
                            ></v-text-field>

                            <!-- Needs Ref [OR]-->
                            <v-combobox
                                clearable
                                multiple
                                dense
                                small-chips
                                :items="tagsStore"
                                item-text="ref"
                                item-value="ref"
                                label="Needs Ref [OR]"
                                v-model="tagNeedsRefCombo"
                                class="mb-5"
                            >
                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                    <v-chip
                                        v-if="item === Object(item)"
                                        v-bind="attrs"
                                        :input-value="selected"
                                        small
                                        color="purple"
                                    >
                                            <span class="pr-2">
                                                {{ item.ref }}
                                            </span>
                                            <v-icon
                                                small
                                                @click="parent.selectItem(item)">
                                                $delete
                                            </v-icon>
                                    </v-chip>
                                </template>
                            </v-combobox>

                            <v-divider></v-divider>

                            <!-- Description-->
                            <v-textarea
                                label="Description"
                                v-model="tag.description"
                                auto-grow
                                outlined
                                class="mt-3"
                            ></v-textarea>

                        </v-card-text>          
          
                        <!-- CRUD Buttons -->
                        <v-card-actions class="py-0 mx-5">
                            <v-spacer></v-spacer>
                            <v-btn small
                                color="purple"
                                v-if="isNew"
                                :loading="loading"
                                :disabled="loading"
                                @click="create"
                                class="mr-2"
                            >
                                <v-icon left>mdi-plus</v-icon>Create
                            </v-btn>

                            <v-btn small
                                color="purple"
                                v-if="!isNew"
                                :loading="loading"
                                :disabled="loading"
                                @click="save"
                                class="mr-2"
                            >
                                <v-icon small>mdi-arrow-up-circle</v-icon>Save
                            </v-btn>
                            <v-btn small
                                v-if="!isNew"
                                :loading="loading"
                                :disabled="loading"
                                color="error"
                                @click="remove"
                                class="mr-2"
                            >
                                <v-icon small>mdi-delete</v-icon>
                                Remove
                            </v-btn>
                        </v-card-actions>

                    </v-col>

                    <v-col cols="5">
                        <v-card flat color="transparent" key="label-form-left">
                            <v-card-text> 
                               <v-text-field  v-for="(iso,index) in isoList" :key="iso"
                                    rounded outlined dense
                                    v-model="tag[iso]"
                                    :label="index + 1 + ': ' + iso.toUpperCase()"
                                ></v-text-field>               
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

    </div>
</template>

<script>
import { Model } from "@/common/model";
import { mapGetters } from 'vuex';

// Label CLASS
class Tag extends Model {
    id = null;
    ref = "";
    parent = null;
    has_label = false;
    add_tag_parent = false;
    level = 0;
    path = "";
    needs_ref = "";
    description = "";
    continent = "";

    onParse() {
   
    }

    onFormat() {
        if (this.ref) {
            this.ref = this.ref.trim();
        }

        if (this.needs_ref) {
            this.needs_ref = this.needs_ref.trim();
        }
    }
}


export default {

    name: 'Tag',

    data: () => ({
        tag: null,
        tagNeedsRefCombo: [],
        channel: null,
        iso3: null,

        loading: false,
        isNew: false,

        // api error
        error: null,

        isoList: ["ar","bg","cs","da","de","el","en","es","et","fi","fr","hu","hy","it","ja","ko","lt","nl","no","pl","pt","ro","ru","sk","sv","tr","th","uk","zh","zh_tw"],
        levelList :[0,1,2,3],
        tokens: [],
        tokensString: "",
        chatGPTstring: "",

        // categories
        tagsList: [],
        parentTag: {},
        onlyName: false,
        onlyNameTerm: null,

    }),

    created() {
        let ref = this.$route.params.ref;
        if (this.$route.params.channel) {
            this.channel = this.$route.params.channel;
        }
        if (ref != "new") {
            this.fetchTag(ref);
            return;
        }
        this.isNew = true;
        this.tag = Tag.init()
    },
    
    watch: {

        tag: {
            deep: true,
            immediate: true,
            handler() {
            
                // if tag exists, build tokens string and tokens...
                if (this.$route.params.ref != "new" && this.tag != null) {
                    this.tokensString = "";
                    this.tokens = [];
                    for (let iso of this.isoList) {
                        this.tokensString += this.tag[iso] + ",";
                        this.tokens.push(this.tag[iso]);
                    } 
                    this.tokensString = this.tokensString.slice(0,-1);
                    this.tokensLength = this.tokensString.split(",").length;
                }

                if (this.tag && this.tag.ref.length > 0) {
                    if (this.parentTag.path && this.parentTag.path.length > 0) {
                        this.tag.path = this.parentTag.path + "/" + this.tag.ref;
                    }
                }

               
            },
        },

        tagsByCategory: {
            deep: true,
            immediate: true,
            handler() {
                if (this.$route.params.channel && this.tagsByCategory) {
                    this.tagsList = this.tagsByCategory[this.$route.params.channel]
                }
            }
        },
            
        chatGPTstring: {
            deep: true,
            immediate: true,
            handler() {
                if (this.chatGPTstring && this.chatGPTstring.length > 0) {
                    
                    // remove last coma if any
                    if(this.chatGPTstring.charAt(this.chatGPTstring.length-1) == ",") {
                        this.chatGPTstring = this.chatGPTstring.slice(0,-1)
                    }

                    this.chatGPTstring = this.chatGPTstring.replace("zh-tw","zh_tw");
                    this.chatGPTstring = this.chatGPTstring.replaceAll("\n","")
                    this.chatGPTstring = this.chatGPTstring.replaceAll('"',"")
                    
                    let blocks = this.chatGPTstring.split(",");
                    this.tokens = [];
                    this.tokensString = "";
                    for (let block of blocks) {
                        if (block.includes(":")) {
                            let token = block.split(":")[1].trim();
                            this.tokens.push(token)
                            this.tokensString += token + ",";
                            let iso = block.split(":")[0]
                            this.tag[iso] = token
                        }
                    }
                    this.tokensString = this.tokensString.slice(0,-1);
                }
            },
        },

        // when we enter only a name, all tag[iso] are set to this name
        onlyNameTerm: {
            deep: true,
            immediate: true,
            handler() {
                if (this.onlyNameTerm) {
                    for (let iso of this.isoList) {
                        this.tag[iso] = this.onlyNameTerm;
                    } 
                }
            }
        },

        parentTag: {
            deep: true,
            immediate: false,
            handler() {
                this.tag.parent = this.parentTag.ref;
                this.tag.level = this.parentTag.level + 1
                if (this.tag.path == 0) {
                    this.tag.path = this.parentTag.path + "/" + this.tag.ref
                }
            }
        }
    },
    


    methods: {

        fetchTag: async function(ref) {
            console.log("fetch tag:",ref);
            this.loading = true;
            this.error = null;
            try {
                let res = await this.$api.get("/tagger/tag/" + ref);
                this.tag = Tag.parse(res.data);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {

                // fetch and set parent tag
                for (let t of this.tagsList) {
                    if (t.ref == this.tag.parent) {
                        this.parentTag = t;
                    }
                }

                // bulid needs tag list
                if (this.tag.needs_ref) {
                    let needsArray = this.tag.needs_ref.split(",");
                    for (let n of needsArray) {
                        for (let t of this.tagsStore) {
                            if (n == t.ref) {
                                this.tagNeedsRefCombo.push(t)
                            }
                        }
                        
                    }
                }

                this.loading = false;
            }
        },

        goTags() {
            this.$router.push({path: "/main/tags"})
        },

        tagsFamilyName(item) {
            let name = ""
            switch (item.level) {
                case 3:
                    name = "· " + item.name;
                    break;
                case 2:
                    name = "-- " + this.$tools.capitalizeString(item.name);
                    break;
                case 1:
                    name = "-- " + this.$tools.capitalizeString(item.name);
                    break;
            
                default:
                    name = item.name.toUpperCase();
                    break;
            }
            return name
        },

        tagsFamilyColorText(item) {
            let color = ""
            switch (item.level) {
                case 3:
                    color = "ml-6 font-weight-bold text-body-2 yellow--text";
                    break;
                case 2:
                    color = "ml-3 font-weight-bold text-body-2 green--text";
                    break;
                case 1:
                    color = "font-weight-bold text-body-1 orange--text";
                    break;
            
                default:
                    color= "font-weight-bold text-body-1 ";
                    break;
            }
            return color
        },

        //* TAG CRUD
        create: async function() {
            this.loading = true;
            this.error = null;

            // checks empty fields
            if (!this.tag.parent || !this.tag.ref ) {
                window.alert("some fields are empty")
                this.loading = false;
                this.error = "some fields are empty"
                return
            }

            if (!this.tag.parent  == 'country' && this.tag.continent.length ==0) {
                window.alert("no continent")
                this.loading = false;
                this.error = "no continent"
                return
            }

            try {
                var payload = this.tag.format();

                // add needs_ref ref from combobox
                payload.needs_ref = ""
                if (this.tagNeedsRefCombo.length > 0) {
                    let needsArray = []
                    for (let tnrc of this.tagNeedsRefCombo) {
                        needsArray.push(tnrc.ref)
                    }
                    payload.needs_ref = needsArray.join(",")
                }   

                await this.$api.post("/tagger/tag", payload);
                this.$router.go(-1);
            } catch(e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
            
        },

        save: async function() {
            console.log("save")
            
            this.loading = true;
            this.error = null;

            // checks empty fields
            if (!this.tag.parent || !this.tag.ref ) {
                window.alert("some fields are empty")
                this.loading = false;
                this.error = "some fields are empty"
                return
            }

            if (!this.tag.parent  == 'country' && this.tag.continent.length ==0) {
                window.alert("no continent")
                this.loading = false;
                this.error = "no continent"
                return
            }
            
            try {
                var payload = this.tag.format();
                
                // add needs_ref ref from combobox
                payload.needs_ref = ""
                if (this.tagNeedsRefCombo.length > 0) {
                    let needsArray = []
                    for (let tnrc of this.tagNeedsRefCombo) {
                        needsArray.push(tnrc.ref)
                    }
                    payload.needs_ref = needsArray.join(",")
                } 

                await this.$api.patch("/tagger/tag/" + this.tag.id, payload);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
                if (this.error == null) {
                    this.$router.go(-1);
                }
            }
            
        },

        remove: async function() {
            this.loading =true;
            this.error = null;
            if (!confirm("Remove TAG \"" + this.tag.en + "\"?")) {
                return;
            }
            try {
                await this.$api.delete("/tagger/tag/" + this.tag.id);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
                if (this.error == null) {
                    this.$router.go(-1);
                }
            }
           
        },
    },

    computed: {
        
        ...mapGetters({
            countriesStore: 'getCountries',
            tagsByCategory: 'getTagsByCategory',
            tagsStore: 'getTags',
        }),
    }

}


</script>

<style>

</style>