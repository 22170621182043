<template>
    <div>
        <v-card flat class="mb-2" color="transparent">
            <!-- loading -->
            <v-progress-linear
                indeterminate
                color="accent"
                :active="loading">
            </v-progress-linear>
            
            <v-card-title class="justify-center">POST CONTENT & CORPUS </v-card-title>
            <!-- error msg -->
            <v-card-text v-if="error" class="text-center font-weight-bold red--text">API Error: {{ error }}</v-card-text>
            
            <v-card-actions>
                <!-- POST ID -->
                <v-text-field
                    v-model="id"
                    label="Post ID"
                    required
                ></v-text-field>
                <v-spacer></v-spacer>
                {{post.source_ref}}
                <span class="ml-2">{{formatDate(post.created_at)}}</span>
                <v-spacer></v-spacer>
                <v-switch :label="stemming ? 'Stemming On':'Stemming Off'" v-model="stemming"></v-switch>
                <v-spacer></v-spacer>
            </v-card-actions>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="fetchCorpus" class="accent--text">GET CORPUS</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="fetchClass" class="accent--text">GET CLASS</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>

        <v-card outlined v-if="corpus" class="mb-2 mx-3">
            <v-card-title class="justify-center">Corpus:  {{corpus.split(" ").length}} words</v-card-title>
            <v-card-text>{{corpus}}</v-card-text>
        </v-card>

        <v-card outlined class="mb-2 mx-3">
            
            <!-- TAGS -->
            <v-card-actions  v-if="post && post.tags">
                <v-spacer></v-spacer>
                <Tags v-if="post && post.tags" 
                    :tags="post.tags" 
                    :pid="id" 
                    :language="post.language" 
                    stream="post_corpus" 
                    status="old"
                />
                <v-spacer></v-spacer>
            </v-card-actions>
            
            <!-- LOOT -->
            <v-card-actions v-if="post && post.loot" class="my-0">
                <v-spacer></v-spacer>
                <LootWidget  v-if="post && post.loot" 
                    :loot="post.loot" 
                    :pid="id" 
                    :language="post.language" 
                    stream="corpusPostTab" 
                    status="old" class="" 
                    key="old"
                />
                <v-spacer></v-spacer>
            </v-card-actions>

        </v-card>

        <v-card outlined v-if="results" class="mb-2 mx-3">
            <v-card-text v-for="(r, index) of results" :key="index" class="text-center my-0 pt-0">
                <h3>{{r.ref}}</h3> 
                <span v-if="r.class == 1" class="font-weight-bold success--text"> CLASS: {{r.class}}</span>
                <span v-else class="font-weight-bold warning--text"> CLASS: {{r.class}}</span>
                <span class="font-weight-bold"> - [{{r.softmax}}]</span>
                <v-card-text v-if="r.class == 1">
                <span v-if="r.tuner_class == 1" class="font-weight-bold success--text"> Tuner CLASS: {{r.tuner_class}}</span>
                <span v-else class="font-weight-bold warning--text"> Tuner CLASS: {{r.tuner_class}}</span>
                <span vclass="font-weight-bold"> - [{{r.tuner_softmax}}]</span>
                <v-divider></v-divider>
            </v-card-text>
                
            </v-card-text>
            
        </v-card>

        <v-card outlined v-if="doc" class="mb-2 mx-3">
            <v-card-title class="justify-center">doc</v-card-title>
            <v-card-text>{{doc}}</v-card-text>
        </v-card>

    </div> 
  
</template>

<script>

import LootWidget from '../Loot/LootWidget.vue'
import Tags from '../Tags/TagsWidget.vue'
import moment from "moment";

export default {

    name: "Corpus",

    data: ()=> ({
        loading: false,
        error: null,
        id: null,
        doc: null,
        corpus: null,
        stemming: true,
        post: [],
        results: null,
    }),

    components: {
        Tags,
        LootWidget,
    },


    created() {
        if (this.$route.params.pid) {
            this.id = this.$route.params.pid.trim()
            this.fetchClass();
        }
    },

    methods: {
        
        fetchCorpus: async function() {
            this.loading = true;
            this.error = null;
            if (this.id) {
                try {
                    let res = await this.$api.get("/tooler/post_corpus/" + this.id.trim() + "?doStemming=" + this.stemming);
                    this.doc = res.data.doc;
                    this.corpus = res.data.corpus;
                    this.post = res.data.post;
                } catch (e) {
                    let data = (e.response || {}).data || "unknown error";
                    this.error = data.message;
                } finally {
                    this.loading = false;
                }

            } else {
                this.error = "no id !"
            }
        },


        fetchClass: async function() {
            this.loading = true;
            this.error = null;
            if (this.id) {
                try {
                    let res = await this.$api.get("/tooler/post_corpus/" + this.id.trim() + "?doStemming=" + this.stemming + "&doClass=true");
                    this.doc = res.data.doc;
                    this.corpus = res.data.corpus;
                    this.post = res.data.post;
                    this.results = res.data.results;
                } catch (e) {
                    let data = (e.response || {}).data || "unknown error";
                    this.error = data.message;
                } finally {
                    this.loading = false;
                }

            } else {
                this.error = "no id !"
            }
        },

        formatDate(date) {
            return moment(date).locale("fr").format("DD/MM/YY HH:mm");
        },
    },

}
</script>

<style>

</style>