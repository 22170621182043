<template>
    <div>
        <v-data-table
            :headers="[
                { text: 'Id', value: 'id', align:'center', class:'text-body-2' },
                { text: 'Created', value: 'created_at', align:'center', class:'text-body-2' },
                { text: 'Name', value: 'ref', align:'center', class:'text-body-2' },
                { text: 'Role', value: 'role', align:'center', class:'text-body-2' },
                { text: 'Lang', value: 'language', align:'center', class:'text-body-2' },
                { text: '% Mitigation', value: 'mitigation', align:'center', class:'text-body-2' },
                { text: 'MSE * 10^6', value: 'mse', align:'center', class:'text-body-2' },
                { text: 'Type', value: 'type', align:'center', class:'text-body-2' },
                { text: 'Samples', value: 'samples', align:'center', class:'text-body-2' },
                { text: 'Stemming', value: 'stemming', align:'center', class:'text-body-2' },
                { text: 'Manager On', value: 'manager_on', align:'center', class:'text-body-2' },
            ]"
            :items="items"
            :item-class= "row_classes"   
            :loading="loading"
            :search="search"
            @click:row="selectRow"
            :footer-props="{
                itemsPerPageText:'modèles par page',
                itemsPerPageOptions: [15,30,-1],
            }"
        >
            <v-progress-linear slot="progress" color="accent" indeterminate></v-progress-linear>

            <!-- TOP BAR -->
            <template v-slot:top>
                <v-toolbar flat color="Dialog">

                    <!-- TABLE TITLE -->
                    <v-toolbar-title class="accent--text">Classifiers<span v-if="total > 1" class="ml-2">({{total}})</span></v-toolbar-title>
                    <v-spacer></v-spacer>
                    
                    <!-- TABLE SEARCH -->
                    <v-text-field
                        dense
                        flat rounded solo single-line
                        v-model="search"
                        :label='$tools.capitalizeWord($i18n.t("search"))'
                        hide-details
                        clearable
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    
                    <!-- NEW CLASSIFIER BUTTON -->
                    <v-btn small fab color="accent" @click="create()" elevation="0">
                        <v-icon color="Dialog">
                            mdi-plus
                        </v-icon>
                    </v-btn>

                </v-toolbar>
            </template>

            <!-- SLOTS -->
            
            <!-- created_at -->
            <template v-slot:[`item.created_at`]="{ item }">
                {{formatDate(item.created_at)}}
            </template>

            <!-- Mitigation-->
            <template v-slot:[`item.mitigation`]="{ item }">
               <span v-if="item.type == 'MLP'">{{item.mitigation}} %</span>
               <span v-if="item.type == 'ONNX'">{{item.mitigation}} %</span>
               <span v-else>-</span>
            </template>

            <!-- Manager On-->
            <template v-slot:[`item.manager_on`]="{ item }">
               <v-icon v-if="item.manager_on" color="success">mdi-checkbox-marked-circle-outline</v-icon>
               <v-icon v-else color="grey">mdi-circle-medium</v-icon>
            </template>

            <!-- Class 1 -->
            <template v-slot:[`item.class_1`]="{ item }">
              {{Math.round(item.samples * item.mitigation / 100)}}
            </template>

            <!-- Type -->
            <template v-slot:[`item.type`]="{ item }">
                <v-btn small outlined v-if="item.type.includes('FT')" color="warning">{{ item.type }}</v-btn>
                <span v-else>{{ item.type }}</span>
            </template>

            <!-- MAPE-->
            <template v-slot:[`item.mse`]="{ item }">
              {{Math.round(1000000 * item.mse )}} 
            </template>

            <!-- MAPE-->
            <template v-slot:[`item.mape`]="{ item }">
              {{item.mape}} %
            </template>
        </v-data-table>

        <!-- error msg -->
        <span v-if="error" class="ml-5 error--text">API Error: {{ error }}</span>

    </div>
</template>

<script>
import moment from "moment";
import classifiers from "@/common/classifiers";

export default {

    name: 'Classifiers',

    data: () => ({
        items: [],
        loading: false,
        total: null,
        error: null,
        search: null,
        scrolling: 0,

        options: {
            page: 0,
            itemsPerPage: 15,
        },
        
    }),

    created() {
        this.search = classifiers.retriveSearch();
        this.scrolling = classifiers.retriveScroll();
        this.fetchClassifers().then( () => {
            window.scrollTo(0,this.scrolling);
            
        });
    },

     watch: {

        options: {
            deep: true,
            immediate: false,
            handler() {
                this.fetchClassifers();
            }
        },

        search: {
            deep: true,
            immediate: false,
            handler() {
                classifiers.setSearch(this.search);
            }
        }
    },

    methods: {
        
        fetchClassifers: async function () {
            this.loading = true;
            try {
                let res = await this.$api.get("classifiers");
                let items = [];
                for (let item of res.data) {
                    items.push(item)
                }
                this.items = items;
                this.total = parseInt(res.headers["x-paginated-items"]);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        row_classes(item) {
            let c = ""
            switch (item.role) {
                case "MASTER":
                    c = "success--text";
                    break;
                case "SLAVE":
                    c = "info--text";
                    break;
                case "OUT":
                    c = "warning--text";
                    break;
            
                default:
                    break;
            }
            return c
        },

        formatDate(date) {
            return moment(date).locale("fr").format("DD/MM/YY");
        },

        // go to Classifier.vue
        selectRow(row) {
            this.$router.push({path: "/main/classifier/" + row.id})
        },
        
        create() {
            this.$router.push({path: "/main/classifier/new"})
        },

    },
   
}
</script>

<style>

</style>