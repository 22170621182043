<template>
    <div>
        <v-card flat outlined class="mb-1" key="deck-parameters">
            <v-card-actions class="my-2">
                <v-spacer></v-spacer>
                <v-btn small @click="eventSeeDeck" class="font-weight-bold Dialog--text" color="success">C{{deckClustersCount}}:P{{deckPostsCount}}</v-btn>
                <v-btn small @click="eventSeeOut" class="font-weight-bold Dialog--text" color="amber">C{{outClustersCount}}:P{{outPostsCount}}</v-btn>
                <v-spacer></v-spacer>
                    <v-text-field
                    outlined dense
                    v-model.number="parameters.day_offset"
                    label="day offset"
                    hide-details=""
                    color="success"
                    @change="eventUpdateParameters"
                    class="mx-2"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-autocomplete
                    dense
                    outlined
                    label="continents"
                    :items="continents"
                    v-model="parameters.continent"
                    hide-details
                    @change="eventUpdateParameters"
                    color="success"
                    class="mx-2"
                >
                    <template v-slot:selection="data">
                        {{ data.item }}
                    </template>
                
                </v-autocomplete>
                <v-spacer></v-spacer>
                <v-text-field
                    outlined dense
                    v-model.number="parameters.daily_batch_hour"
                    label="Yesterday start hour"
                    hide-details=""
                    color="success"
                    class="mx-2"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-text-field
                    outlined dense
                    v-model.number="parameters.doc_epsilon"
                    label="Doc Epsilon x10"
                    hide-details=""
                    color="success"
                    class="mx-2"
                    @change="eventUpdateParameters"
                ></v-text-field>
                <v-text-field
                    outlined dense
                    v-model.number="parameters.tags_loot_epsilon"
                    label="Tags Loot Epsilon x10"
                    hide-details=""
                    color="success"
                    class="mx-2"
                    @change="eventUpdateParameters"
                ></v-text-field>
                <v-text-field
                    outlined dense
                    v-model="parameters.publisher_model_ref"
                    label="Publisher model ref"
                    hide-details=""
                    color="success"
                    class="mx-2"
                    @change="eventUpdateParameters"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn small @click="eventFetchNewDeck" color="success"  class="font-weight-bold Dialog--text" :loading="new_loading">NEW</v-btn>
                <v-btn small @click="eventFetchLastPosts" color="blue" class="font-weight-bold Dialog--text ml-2" :loading="last_loading">LAST</v-btn>
                <v-spacer></v-spacer>
                <v-btn rounded small class="ml-2">{{delay}} ms</v-btn>
                <v-btn rounded small class="ml-2">max: {{max_posts_count}}p</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
       
        </v-card>
    </div>
</template>

<script>
import deck from "@/common/deck";

export default {

    name: 'Parameters',

    props:['clusters','delay','max_posts_count', 'new_loading','last_loading'],

    data: () => ({
       
        parameters: null,

        continents: [
            "all",
            "down-under",
            "asia",
            "middle-east",
            "soviet",
            "turkiye",
            "europe",
            "africa",
            "north-america",
            "mexico",
            "caribbean",
            "south-america",
            "oceania",
            "antartica",
        ],
    }),

    created() {
        this.parameters = deck.retriveParameters();
    },

    methods: {

        eventSeeOut() {
            this.parameters.see_out = true;
            this.$emit("eventUpdateParameters", this.parameters)
        },

        eventSeeDeck() {
            this.parameters.see_out = false;
            this.$emit("eventUpdateParameters", this.parameters)
        },

        eventUpdateParameters(){
            deck.setParameters(this.parameters);
            this.$emit("eventUpdateParameters", this.parameters)
        },

        eventFetchNewDeck() {
            this.$emit("eventFetchNewDeck");
        },

        eventFetchLastPosts() {
            this.$emit("eventFetchLastPosts");
        },

        clear() {
            this.parameters.continent = "all";
            deck.setParameters(this.parameters);
            this.$emit("eventUpdateParameters", this.parameters)
        }
    },

    computed: {
        
        outClustersCount() {
            return this.clusters.filter(c => c.is_out).length;
        },
        deckClustersCount() {
            return this.clusters.filter(c => !c.is_out).length;
        },
        outPostsCount() {
            return this.clusters.reduce((count, c) => count + c.posts.filter(p => p.is_out).length, 0);
        },
        deckPostsCount() {
            return this.clusters.reduce((count, c) => count + c.posts.filter(p => !p.is_out).length, 0);
        },
        totalPostsCount() {
            return this.clusters.reduce((count, c) => count + c.posts.length, 0);
        }
    }   
}
</script>

<style>

</style>