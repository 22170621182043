<template>
    <div>
        <v-card flat color="Background" key="slaver-view">

            <!-- loading -->
            <v-progress-linear
                indeterminate
                color="accent"
                :active="loading">
            </v-progress-linear>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-select
                    outlined
                    dense
                    :items="classifiers"
                    v-model="selectedClassifier"
                    label="slave classifiers"
                    item-text="ref"
                    item-value="ref"
                    hide-details
                    clearable
                ></v-select>
                <v-spacer></v-spacer>
                <v-btn v-if="selectedClassifier" small @click="fetchPosts" class="" color="">GET POSTS</v-btn>
                <v-spacer></v-spacer>
                <span v-if="total">is_confirmed_class Posts: {{posts.length}}/{{options.itemsPerPage}} - Total: {{total}}</span>
                <v-spacer></v-spacer>
            </v-card-actions>


            <!-- error msg -->
            <span v-if="error && error != 'empty'" class="ml-5 mt-2 error--text">API Error: {{ error }}</span>

            <!-- no post msg -->
            <v-card v-if="error == 'empty' || total == 0" flat color="transparent">
                <v-card-text class="text-center">{{$i18n.t("noMorePost")}}</v-card-text>
            </v-card>

            <v-sheet 
                outlined rounded 
                v-for="(post, index) in posts" :key="'post_'+post.id" 
                class="mb-2 mx-3" :color="postColor(post)"
            >
                <v-card flat class="d-flex">
                    <Widget 
                        :post="post" 
                        :index="index" 
                        position="vertical" 
                        :classifier="selectedClassifier"
                        @eventVote="eventVote" 
                        :key='"v" + post.id'
                    ></Widget>
                    <Post 
                        :post="post" 
                        :index="index"
                        @eventGoSingle="eventOpenSingleDialog"
                        @eventGoCorpus="eventGoCorpus"
                        @eventGoTags="eventGoTags"
                        @eventGoLoot="eventGoLoot"
                        :key='"p" + post.id'
                        tab="slaver"
                    ></Post>
                </v-card>
            </v-sheet>


            <!-- DIALOG SINGLE -->
            <v-dialog 
                v-model="dialogSingle"
                key="slaver-dialog-single"
                fullscreen
            >  
                <!-- v-card needed for triggering fullscreen-->    
                <v-card> 
                    <Single v-if="dialogSingle"
                        :single="dialogSinglePost" 
                        :index="dialogSingleIndex"
                        tab="slaver"
                        colorView="warning"
                        @eventCloseSingleDialog="eventCloseSingleDialog"
                        @eventVote="eventVote" 
                        @eventTranslation="eventTranslation"
                        @eventGoTags="eventGoTags"
                        @eventGoLoot="eventGoLoot"
                        @eventGoCorpus="eventGoCorpus"
                    ></Single>
                </v-card>
            </v-dialog>
          
        </v-card>
    </div>
</template>

<script>
import Post from '../components/Post'
import Widget from './SlaverWidget'
import Single from '../Trainer/TrainerDialogSingle'

export default {

    name: 'Slaver',
    
    components: {
        Post,
        Widget,
        Single,
    },

    data: () => ({
        loading: false,
        error: null,
        posts: [],

        total: null,
        page: 1,
        options: {
            itemsPerPage: 100,
        },

        selectedClassifier: "",
        classifiers: [],

        // dialog single
        dialogSingle: false,
        dialogSinglePost: null,
        dialogSingleIndex: null,
    }),

    created() {
        this.fetchSlaveClassifiers();
    },

    watch: {
        selectedClassifier: {
            deep: true,
            immediate: true,
            handler() {
                if (this.classifiers.length > 0) {
                    this.fetchPosts();
                }
            }
        }
    },

    methods: {

        fetchPosts: async function() {
            console.log("fetch posts slave classifier", this.selectedClassifier)
            this.loading = true
            this.error = null;
            this.posts = [];

            let filter = (
                "?size=" + this.options.itemsPerPage + 
                "&page=" + (this.page - 1)  +
                "&classifier=" + this.selectedClassifier
            );

            try {
                let res = await this.$api.get("/profile/slaver/list" + filter);
                for (let r of res.data) {
                    let p = r.post;
                    p.slave_softmax = r.slave_softmax
                    this.posts.push(p)
                }
                this.total = parseInt(res.headers["x-paginated-items"]);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error =  data.message;
            } finally {
                this.loading = false;
            }
        },

        fetchSlaveClassifiers: async function () {
            this.loading = true;
            try {
                let res = await this.$api.get("classifiers?role=slave");
                this.classifiers = res.data;
                this.total = parseInt(res.headers["x-paginated-items"]);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        //* VOTE
        eventVote(index) {
            this.posts.splice(index,1)
            this.total --;
            this.dialogSingle = false;
        },

       //* Dialog Single
        eventOpenSingleDialog(index) {
            this.dialogSingle = true;
            this.dialogSinglePost = this.posts[index];
            this.dialogSingleIndex = index;
        },

        eventCloseSingleDialog(){
            this.dialogSingle = false;
            this.dialogSinglePost = null;
            this.dialogSingleIndex = null;
        },

        // Go tools Tags
        eventGoTags(index) {
            this.$router.push({path: "/main/tools_post_tags_tab/" + this.posts[index].id})
        },

        // Go tools Loot
        eventGoLoot(index) {
            this.$router.push({path: "/main/tools_post_loot_tab/" + this.posts[index].id})
        },

        // Go tools Corpus
        eventGoCorpus(index) {
            this.$router.push({path: "/main/tools_post_corpus_tab/" + this.posts[index].id})
        },

        eventTranslation(index,output_language,content,description) {
            console.log("eventTranslation",index, output_language)
            if (this.posts[index].translations.length > 0) {
                for (let tr of this.posts[index].translations) {
                    if (tr.output_language == output_language) {
                        tr.content = content;
                        tr.description = description;
                    }
                }
            }
        },

        postColor(post) {
            let color =""
            if (post.slave_softmax >= 0.5 && post.softmax < 0.5 ) {
                color="warning"
            }
    
            return color
        },
    },

    computed: {
       
    }
}
</script>

<style>

</style>